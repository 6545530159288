import React, { createContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

// Création du contexte Auth
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fonction pour connecter l'utilisateur
  useEffect(() => {
  //  console.log("AuthContext - User chargé depuis localStorage :", user);
  }, [user]);
  
  const login = useCallback((token) => {
    localStorage.setItem('authToken', token);
    const decoded = jwtDecode(token);
    // console.log("AuthContext - User décodé au login :", decoded);
    setAuthToken(token);
    setUser(decoded);
    setLoading(false);
  }, []);

  // Fonction pour déconnecter l'utilisateur
  const logout = useCallback(() => {
    // Supprimer le token du localStorage
    localStorage.removeItem('authToken');
    // Réinitialiser les états utilisateur et authToken
    setAuthToken(null);
    setUser(null);
    setLoading(false);
  
    // Utiliser Promise.resolve pour garantir la mise à jour des états
    Promise.resolve().then(() => {
      // Rediriger vers la page de connexion après la mise à jour de l'état
      navigate('/login');
    });
  }, [navigate]);

  // Charger les informations d'authentification depuis localStorage au démarrage
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.exp * 1000 > Date.now()) {
        setAuthToken(token);
        setUser(decoded);
      } else {
        // Le token est expiré
        logout();
      }
    }
    setLoading(false);
  }, [logout]);

  // Fonction pour rafraîchir le token (à appeler périodiquement si le backend le supporte)
  const refreshAuthToken = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/refresh-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to refresh token');
      }
      const data = await response.json();
      console.log("Nouveau token avec rôle :", jwtDecode(data.token));
      login(data.token);
    } catch (error) {
      console.error('Erreur lors du rafraîchissement du token:', error);
      logout();
    }
  }, [authToken, login, logout]);
  
  // Vérifier le rafraîchissement périodiquement
  useEffect(() => {
    let interval;
    if (authToken) {
      // Rafraîchir le token toutes les 15 minutes (par exemple)
      interval = setInterval(() => {
        refreshAuthToken();
      }, 15 * 60 * 1000);
    }
    return () => clearInterval(interval);
  }, [authToken, refreshAuthToken]);

  // Fonction pour mettre à jour le profil de l'utilisateur
  const updateProfile = async (updatedUserInfo) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(updatedUserInfo),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile');
      }

      const data = await response.json();
      // Mettre à jour l'état local de l'utilisateur après la mise à jour
      setUser(data);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, authToken, login, logout, refreshAuthToken, updateProfile, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
