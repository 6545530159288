import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { Box, TextField, Button, Typography, Container, Alert, Collapse, IconButton, InputAdornment, Stack } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { login, authToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
  //  console.log("Token dans Login (via contexte):", authToken);
  }, [authToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.message === 'Adresse email non trouvée. Veuillez vérifier vos informations.') {
          setErrorMessage('Utilisateur non trouvé. Veuillez vérifier votre email.');
        } else if (errorData.message === 'Mot de passe incorrect. Veuillez réessayer.') {
          setErrorMessage('Mot de passe incorrect. Veuillez réessayer.');
        } else {
          setErrorMessage('Une erreur inconnue est survenue.');
        }
        setShowAlert(true);
        return;
      }
  
      const data = await response.json();
      login(data.token);
      navigate('/');
    } catch (error) {
      console.error('Erreur lors de la connexion:', error);
      setErrorMessage('Erreur de connexion. Veuillez réessayer plus tard.');
      setShowAlert(true);
    }
  };
  

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <Helmet>
        <title>Yoco - Connexion</title>
        <meta name="keywords" content="coaching, sport, nutrition, bien-être, performance" />
      </Helmet>
    <Container sx={{ minHeight:'80vh', display: 'flex', alignItems: 'center' }} maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          py: 4,
          px: { xs: 2, sm: 4 },
          borderRadius: 2,
          border: '1px solid #DDD',
          backgroundColor: 'white',
        }}
      >
        <Typography variant="h2" sx={{ mb: 0 }}>
          Connexion
        </Typography>
        <Collapse in={showAlert} sx={{ width: '100%' }}>
          <Alert
            severity="error"
            onClose={() => setShowAlert(false)}
            sx={{ mt:2, mb: 0 }}
          >
            {errorMessage}
          </Alert>
        </Collapse>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            sx={{ mb:0 }}
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 0 }}
          >
            Se connecter
          </Button>
          <Stack direction="row" justifyContent="center" sx={{ mt: 3, mb:1 }}>
            <Link to="/forgot-password" style={{ textDecoration: 'none', color: 'grey' }}>
              <Typography variant='body2' >Mot de passe oublié ?</Typography>
            </Link>
          </Stack>
        </Box>
      </Box>
    </Container>
    </>
  );
};

export default Login;
