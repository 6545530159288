import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import Comments from './Comments'; // Importer le composant Comments

const DIETARY_TRANSLATIONS = {
  vegetarian: 'Végétarien',
  vegan: 'Vegan',
  'gluten-free': 'Sans gluten',
  'dairy-free': 'Sans produits laitiers',
  paleo: 'Paléo',
  keto: 'Kéto',
  other: 'Autre',
};

const GeneralInfo = ({ user, dietaryPreferences, goals, allergies, summary, targetMacros, planId }) => {
  const totalMacros = targetMacros?.carbohydrates + targetMacros?.fats + targetMacros?.proteins || 100;
  const carbsPercentage = (targetMacros?.carbohydrates / totalMacros) * 100 || 0;
  const fatsPercentage = (targetMacros?.fats / totalMacros) * 100 || 0;
  const proteinsPercentage = (targetMacros?.proteins / totalMacros) * 100 || 0;

  return (
    <Box>
      {/* Bloc principal en haut */}
      <Box sx={{ border: '1px solid #DDD', borderRadius: 1, mb: 1, p: { xs: 2, sm: 3 } }}>
        {user ? (
          <>
            <Typography sx={{ mb: 1.2 }} variant="h3">
              {user.firstName} {user.lastName}
            </Typography>
            <Box>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <Chip
                  label={
                    dietaryPreferences?.length > 0
                      ? dietaryPreferences
                          .map((pref) => DIETARY_TRANSLATIONS[pref] || pref)
                          .join(', ')
                      : 'Aucun'
                  }
                />
                {allergies && allergies.length > 0 && (
                  <Chip label={allergies.join(', ')} />
                )}
              </Box>
              <Typography sx={{ mt: 2, mb: 2 }} variant="body2">
                <strong>Objectif :</strong> {goals || 'Non spécifié'}
              </Typography>
            </Box>

            {/* Jauge pour les macros */}
            {targetMacros && (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    height: 30,
                    borderRadius: 5,
                    overflow: 'hidden',
                    border: '1px solid #ddd',
                  }}
                >
                  <Box
                    sx={{
                      width: `${carbsPercentage}%`,
                      textAlign: 'center',
                      backgroundColor: 'primary.main',
                    }}
                  >
                    <Typography
                      sx={{
                        lineHeight: '30px',
                        fontWeight: 'bold',
                        fontSize: { xs: '0.6em', sm: '0.8em' },
                      }}
                      variant="body2"
                    >
                      Glucides : {targetMacros.carbohydrates}%
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: `${fatsPercentage}%`,
                      textAlign: 'center',
                      backgroundColor: 'secondary.main',
                    }}
                  >
                    <Typography
                      sx={{
                        lineHeight: '30px',
                        fontWeight: 'bold',
                        fontSize: { xs: '0.6em', sm: '0.8em' },
                      }}
                      variant="body2"
                    >
                      Lipides : {targetMacros.fats}%
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: `${proteinsPercentage}%`,
                      textAlign: 'center',
                      backgroundColor: 'white',
                    }}
                  >
                    <Typography
                      sx={{
                        lineHeight: '30px',
                        fontWeight: 'bold',
                        fontSize: { xs: '0.6em', sm: '0.8em' },
                      }}
                      variant="body2"
                    >
                      Protéines : {targetMacros.proteins}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}



          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              minHeight: '75px',
              alignItems: 'center',
            }}
          >
            <Typography textTransform="uppercase" variant="body2">
              Aucun utilisateur associé
            </Typography>
          </Box>
        )}
      </Box>

        {/* Colonne résumé */}
        <Box sx={{ mt:3 , mb:2 }} >
          <Typography variant="h5" sx={{ mb: 1.5 }}>
            Bilan
          </Typography>
          <Typography variant="body2">
            {summary || 'Aucun résumé disponible.'}
          </Typography>

          <Box sx={{ mt:1, mb:4 }}>
              <Comments planId={planId} />
            </Box>

        </Box>

    </Box>
  );
};

export default GeneralInfo;
