import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, Avatar, ToggleButton, ToggleButtonGroup, Switch } from '@mui/material';
import { getRecipeEmoji } from '../../utils/getRecipeEmoji';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeIcon from '@mui/icons-material/Home';

const ShoppingList = ({ shoppingList }) => {
  const [hiddenIngredients, setHiddenIngredients] = useState(() => {
    const saved = localStorage.getItem('hiddenIngredients');
    return saved ? JSON.parse(saved) : [];
  });

  const [filter, setFilter] = useState('toBuy'); // Par défaut, montrer les ingrédients à acheter

  const toggleIngredientVisibility = (ingredient) => {
    setHiddenIngredients((prev) => {
      const updated = prev.includes(ingredient)
        ? prev.filter((item) => item !== ingredient) // Retirer l'ingrédient
        : [...prev, ingredient]; // Ajouter l'ingrédient

      localStorage.setItem('hiddenIngredients', JSON.stringify(updated));
      return updated;
    });
  };

  const filteredList = shoppingList.filter((item) => {
    if (filter === 'toBuy') {
      return !hiddenIngredients.includes(item.ingredient);
    } else if (filter === 'atHome') {
      return hiddenIngredients.includes(item.ingredient);
    }
    return true;
  });

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1.5, mt:4 }}>
        <Typography variant="h5" component="div">
          Liste de courses
        </Typography>
      </Box>

      {/* Boutons de filtre */}
      <ToggleButtonGroup
        value={filter}
        exclusive
        onChange={(e, newFilter) => setFilter(newFilter)}
        sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}
      >
        <ToggleButton value="toBuy" sx={{ flex: 1, textTransform: 'none', fontWeight: 'bold' }}>
          <ShoppingCartIcon sx={{ mr: 1, fontSize:'1.5em' }} /> À acheter
        </ToggleButton>
        <ToggleButton value="atHome" sx={{ flex: 1, textTransform: 'none', fontWeight: 'bold' }}>
          <HomeIcon sx={{ mr: 1, fontSize:'1.5em' }} /> Chez moi
        </ToggleButton>
      </ToggleButtonGroup>

      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))',
        }}
      >
        {filteredList.map((item, index) => (
          <Card
            key={index}
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              border: '1px solid #DDD',
              padding: 2,
              boxShadow: 'none',
              '&:hover': {
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
              <Switch
                checked={hiddenIngredients.includes(item.ingredient)}
                onChange={() => toggleIngredientVisibility(item.ingredient)}
                color="primary"
              />
            </Box>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                textAlign: 'left',
                p: 0,
                paddingBottom: '0 !important',
              }}
            >
              <Avatar
                sx={{
                  fontSize: '1.5rem',
                  background: '#EEE',
                  mb: 1,
                  width: 46,
                  height: 46,
                }}
              >
                {getRecipeEmoji(item.ingredient)}
              </Avatar>
              <Typography variant="h6" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                {`${item.quantity} ${item.unit || ''}`} g {/* Affiche la quantité et l'unité */}
              </Typography>
              <Typography variant="body2" sx={{ mt: 0.5 }}>
                {item.ingredient}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default ShoppingList;
