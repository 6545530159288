// ClientSelection.js - Enhanced Component for Client Selection with Drawer and Search Capability
import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Drawer,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UserMin from '../../components/plans/UserMin';

const ClientSelection = ({ clients, selectedClient, setSelectedClient, summary, setSummary, nextStep, userPreferences }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  // eslint-disable-next-line
  const [selectedClientDetails, setSelectedClientDetails] = useState(null);

  const filteredClients = clients.filter((client) =>
    `${client.firstName} ${client.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setSearchTerm('');
  };

  const handleClientSelect = (client) => {
    setSelectedClient(client._id);
    setSelectedClientDetails(client);
    closeDrawer();
  };

  return (
    <>
      <Typography textTransform="uppercase" variant="body2" sx={{ marginBottom: 0.5 }}>
        étape 1
      </Typography>
      <Typography variant="h3" sx={{ marginBottom: 2 }}>
        Le client
      </Typography>

      <Button variant="contained" onClick={openDrawer} sx={{ marginBottom: 2 }} startIcon={<AddCircleIcon />}>
        Ajouter
      </Button>

      <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
        <Box sx={{ width: 400, padding: 3 }}>
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            Sélection du client
          </Typography>
          <TextField
            label="Rechercher"
            type="text"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <List>
            {filteredClients.map((client) => (
              <ListItem key={client._id} disablePadding>
                <ListItemButton onClick={() => handleClientSelect(client)}>
                  <ListItemText primary={`${client.firstName} ${client.lastName}`} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>


        <Box sx={{ marginBottom: 4}}>

          <UserMin selectedClient={selectedClient} userPreferences={userPreferences} />

          <Box>
            <TextField
              label="Bilan"
              fullWidth
              multiline
              rows={4}
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              sx={{ flex: 1, height: '100%', '.MuiInputBase-root': { height: '100%' } }}
            />
          </Box>
        </Box>

      <Box sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="primary" onClick={nextStep} disabled={!selectedClient}>
          Suivant
        </Button>
      </Box>
    </>
  );
};

export default ClientSelection;