// TopBar.js
import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, Menu, MenuItem, Avatar, Typography, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu'; // Burger icon
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonIcon from '@mui/icons-material/Person';
import { AuthContext } from '../context/AuthContext'; // Import du contexte

const TopBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false); // State for mobile drawer
  const open = Boolean(anchorEl);
  const { user, logout } = useContext(AuthContext);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  const toggleMobileMenu = () => {
    setMobileOpen(!mobileOpen);
  };

  const mobileMenuItems = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleMobileMenu} onKeyDown={toggleMobileMenu}>
      <List>
        {user ? (
          <>
            <ListItem>
              <ListItemButton onClick={handleLogout}>
                <Avatar sx={{ bgcolor: '#eee', width: 28, height: 28, mr: 1 }}>
                  <ExitToAppIcon sx={{ color: 'black', fontSize: '0.9em' }} fontSize="small" />
                </Avatar>
                <ListItemText primary="Déconnexion" />
              </ListItemButton>
            </ListItem>
          </>
        ) : (
          <>
            <ListItem>
              <ListItemButton component={Link} to="/about">
                <ListItemText sx={{ textTransform:'uppercase' }} primary="À propos" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton component={Link} to="/offers">
                <ListItemText sx={{ textTransform:'uppercase' }} primary="Offres" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton component={Link} to="/contact">
                <ListItemText sx={{ textTransform:'uppercase' }} primary="Contact" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton component={Link} to="/login">
                <Avatar sx={{ bgcolor: '#eee', width: 28, height: 28 }}>
                  <PersonIcon sx={{ color: 'black', fontSize: '0.9em' }} fontSize="small" />
                </Avatar>
                <ListItemText sx={{ textTransform:'uppercase', ml:1 }} primary="Connexion" />
              </ListItemButton>
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar position="static" sx={{ px: { xs: 2, sm: 0 }, backgroundColor: 'black', boxShadow: 'none' }}>
      <Toolbar
        sx={{
          px: { xs: 0, sm: 6 },
          maxWidth: '880px',
          width: { xs: '-webkit-fill-available', md: '100%' },
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button color="inherit" component={Link} to="/home">
          <img alt="logo" src="/static/logo.svg" height="30px" />
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        {user ? (
          <>
            {/* Desktop account menu */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                sx={{ color: 'white' }}
              >
                <AccountCircleIcon sx={{ fontSize: '1.1em' }} />
              </IconButton>
            </Box>
            <Menu
              elevation={3}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>
                <Avatar sx={{ bgcolor: '#eee', width: 28, height: 28, mr: 1 }}>
                  <ExitToAppIcon sx={{ color: 'black', fontSize: '0.9em' }} fontSize="small" />
                </Avatar>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  Déconnexion
                </Typography>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'center', gap: 1 }}>
            <Button color="inherit" component={Link} to="/about" sx={{ color: 'white' }}>
              À propos
            </Button>
            <Button color="inherit" component={Link} to="/offers" sx={{ color: 'white' }}>
              Offres
            </Button>
            <Button color="inherit" component={Link} to="/contact" sx={{ color: 'white' }}>
              Contact
            </Button>
            <Button color="inherit" component={Link} to="/login" sx={{ color: 'white', m: 0, p: 0 }}>
              <Avatar sx={{ bgcolor: '#eee', width: 28, height: 28 }}>
                <PersonIcon sx={{ color: 'black', fontSize: '0.9em' }} fontSize="small" />
              </Avatar>
            </Button>
          </Box>
        )}
        <IconButton
          size="large"
          edge="end"
          aria-label="menu"
          aria-controls="mobile-menu"
          aria-haspopup="true"
          onClick={toggleMobileMenu}
          color="inherit"
          sx={{ display: { xs: 'flex', sm: 'none' }, color: 'white' }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor="right" open={mobileOpen} onClose={toggleMobileMenu}>
          {mobileMenuItems}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
