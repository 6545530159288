import React from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { Facebook, Instagram, Mail, Phone, Twitter, Favorite, FlashOn, Psychology, SelfImprovement, Work, TrendingUp } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function HomePage() {
  return (
    <>
      <Helmet>
        <title>Yoco - Accueil</title>
        <meta name="description" content="Transformez votre vie avec Yoco. Coaching sportif, nutrition, et bien-être mental pour vous accompagner vers vos objectifs." />
        <meta name="keywords" content="coaching, sport, nutrition, bien-être, performance" />
      </Helmet>
    <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', width: { xs: '-webkit-fill-available', md: '100%' }, margin: '0 auto' }}>

      {/* Hero Section */}
      <Box sx={{ mt: 6, mb: 6, display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' }, gap: 4 }}>
        <Box sx={{ flex: 1 }}>
          <Typography textTransform="uppercase" variant="h1" sx={{ mb: 2 }}>
            Devenez inarrêtable
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.1em' }}>
            Votre partenaire pour la performance, la nutrition, et le mental.
          </Typography>
          <Box>
            <Button variant="contained" component={Link} to="/contact" color="primary" size="large" sx={{ mt: 3 }}>
              Rejoignez-nous
            </Button>
          </Box>
        </Box>
        <Box sx={{ flex: 1, textAlign: { xs: 'center', md: 'right' }, mt: { xs: 4, md: 0 } }}>
          <img src="/static/hero-image.png" alt="Hero" style={{ width: '100%', maxWidth: '400px', height: 'auto', objectFit: 'cover', borderRadius:4 }} />
        </Box>
      </Box>

      {/* Features Section */}
      <Box sx={{ mt: 6, mb: 6, display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
        <Box sx={{ flex: '1 1 calc(33.333% - 16px)', display: 'flex', flexDirection: 'column', textAlign:'center',alignItems: 'center', justifyContent: 'center', px: 3, py: 5,  backgroundColor: '#fff', borderRadius: 2, border: '1px solid #DDD' }}>
          <Favorite sx={{ fontSize: 60, color: '#000' }} />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Amélioration santé physique & mentale
          </Typography>
        </Box>
        <Box sx={{ flex: '1 1 calc(33.333% - 16px)', display: 'flex', flexDirection: 'column', textAlign:'center',alignItems: 'center', justifyContent: 'center', px: 3, py: 5,  backgroundColor: '#fff', borderRadius: 2, border: '1px solid #DDD' }}>
          <FlashOn sx={{ fontSize: 60, color: '#000' }} />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Énergie décuplée
          </Typography>
        </Box>
        <Box sx={{ flex: '1 1 calc(33.333% - 16px)', display: 'flex', flexDirection: 'column', textAlign:'center',alignItems: 'center', justifyContent: 'center', px: 3, py: 5,  backgroundColor: '#fff', borderRadius: 2, border: '1px solid #DDD' }}>
          <Psychology sx={{ fontSize: 60, color: '#000' }} />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Plus productif, Plus créatif
          </Typography>
        </Box>
        <Box sx={{ flex: '1 1 calc(33.333% - 16px)', display: 'flex', flexDirection: 'column', textAlign:'center',alignItems: 'center', justifyContent: 'center', px: 3, py: 5,  backgroundColor: '#fff', borderRadius: 2, border: '1px solid #DDD' }}>
          <SelfImprovement sx={{ fontSize: 60, color: '#000' }} />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Estime de soi, Confiance en soi
          </Typography>
        </Box>
        <Box sx={{ flex: '1 1 calc(33.333% - 16px)', display: 'flex', flexDirection: 'column', textAlign:'center',alignItems: 'center', justifyContent: 'center', px: 3, py: 5,  backgroundColor: '#fff', borderRadius: 2, border: '1px solid #DDD' }}>
          <Work sx={{ fontSize: 60, color: '#000' }} />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Qualité de Vie au Travail, à la ville, à la maison
          </Typography>
        </Box>
        <Box sx={{ flex: '1 1 calc(33.333% - 16px)', display: 'flex', flexDirection: 'column', textAlign:'center',alignItems: 'center', justifyContent: 'center', px: 3, py: 5,  backgroundColor: '#fff', borderRadius: 2, border: '1px solid #DDD' }}>
          <TrendingUp sx={{ fontSize: 60, color: '#000' }} />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Plus performant
          </Typography>
        </Box>
      </Box>

      {/* Call to Action Section */}
      <Box sx={{ backgroundColor: '#000', color: '#fff', width: '100%', py: 5, textAlign: 'center', borderRadius:2 }}>
        <Typography variant="h2" sx={{  mb: 3 }}>
          Prêt à transformer votre vie ?
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '1.1em', mb:4, px: { xs: 2, sm: 15 } }}>
          Rejoignez la communauté et commencez votre aventure vers une meilleure version de vous-même.
        </Typography>
        <Button variant="contained" component={Link} to="/contact" color="primary" size="large">
          Inscrivez-vous maintenant
        </Button>
      </Box>

      {/* Social Media Section */}
      <Box sx={{ mt: 5, textAlign: 'center', py: 3, width: '100%' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Suivez-nous sur les réseaux sociaux
        </Typography>
        <Box>
          <IconButton color="inherit" sx={{ color: '#000' }}>
            <Facebook />
          </IconButton>
          <IconButton color="inherit" sx={{ color: '#000' }}>
            <Instagram />
          </IconButton>
          <IconButton color="inherit" sx={{ color: '#000' }}>
            <Twitter />
          </IconButton>
          <IconButton color="inherit" component={Link} to="/contact" sx={{ color: '#000' }}>
            <Mail />
          </IconButton>
          <IconButton color="inherit"  sx={{ color: '#000' }}>
            <Phone />
          </IconButton>
        </Box>
      </Box>
    </Box>
    </>
  );
}

export default HomePage;