import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, Drawer } from '@mui/material';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import NoDrinksIcon from '@mui/icons-material/NoDrinks';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import OpacityIcon from '@mui/icons-material/Opacity';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const RECOMMENDATIONS_ICONS = {
  sleep: <LocalHotelIcon />,
  alcohol: <NoDrinksIcon />,
  dairyProducts: <FreeBreakfastIcon />,
  hydration: <OpacityIcon />,
  physicalActivity: <FitnessCenterIcon />,
  stressManagement: <SelfImprovementIcon />,
  nutrition: <RestaurantIcon />,
  others: <MoreHorizIcon />,
};

const RECOMMENDATIONS_TITLES = {
  sleep: 'Sommeil',
  alcohol: 'Alcool',
  dairyProducts: 'Produits laitiers',
  hydration: 'Hydratation',
  physicalActivity: 'Activité physique',
  stressManagement: 'Gestion du stress',
  nutrition: 'Nutrition',
  others: 'Autres',
};

const Recommendations = ({ recommendations }) => {
  const [selectedRecommendation, setSelectedRecommendation] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleCardClick = (key) => {
    setSelectedRecommendation({ key, value: recommendations[key] || 'Aucune recommandation disponible.' });
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedRecommendation(null);
  };

  return (
    <Box sx={{ px:0, mt:2 }}>
      <Box
        sx={{
          display: 'grid',
          gap: 2, // Espace entre les cards
          gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))', // Colonnes adaptatives
          justifyContent: 'center',
        }}
      >
        {Object.entries(recommendations).map(([key, value]) => (
          <Card
            key={key}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #DDD',
              p:0,
              boxShadow: 'none',
              '&:hover': {
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              },
              aspectRatio: '4/3', // Maintient un ratio uniforme
            }}
            onClick={() => handleCardClick(key)}
          >
            <CardContent sx={{ textAlign: 'center', paddingBottom:'0 !important', p:0 }}>
              {RECOMMENDATIONS_ICONS[key]}
              <Typography variant="body2" sx={{ mt: 1 }}>
                {RECOMMENDATIONS_TITLES[key]}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>

      <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
        <Box sx={{ width: 300, p: 3 }}>
          {selectedRecommendation && (
            <>
              <Typography variant="h5" sx={{ mb: 2 }}>
                {RECOMMENDATIONS_TITLES[selectedRecommendation.key]}
              </Typography>
              <Typography variant="body2">{selectedRecommendation.value}</Typography>
            </>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default Recommendations;
