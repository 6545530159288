export const getRecipeEmoji = (recipeName) => {
  const lowerName = recipeName.toLowerCase();

  // Légumes
  if (lowerName.includes('brocoli')) return '🥦';
  if (lowerName.includes('carotte')) return '🥕';
  if (lowerName.includes('tomate')) return '🍅';
  if (lowerName.includes('poivron')) return '🫑';
  if (lowerName.includes('aubergine')) return '🍆';
  if (lowerName.includes('champignon')) return '🍄';
  if (lowerName.includes('courgette')) return '🥒';
  if (lowerName.includes('épinard')) return '🥬';
  if (lowerName.includes('pomme de terre')) return '🥔';
  if (lowerName.includes('maïs')) return '🌽';
  if (lowerName.includes('salade')) return '🥗';
  if (lowerName.includes('oignon')) return '🧅';
  if (lowerName.includes('ail')) return '🧄';
  if (lowerName.includes('concombre')) return '🥒';
  if (lowerName.includes('radis')) return '🌶️';
  if (lowerName.includes('betterave')) return '🧃';
  if (lowerName.includes('fenouil')) return '🥬';
  if (lowerName.includes('chou-fleur')) return '🧄';
  if (lowerName.includes('chou')) return '🥬';
  if (lowerName.includes('navet')) return '⚪';
  if (lowerName.includes('rutabaga')) return '🥔';
  if (lowerName.includes('céleri')) return '🥬';
  if (lowerName.includes('poireau')) return '🥬';
  if (lowerName.includes('patate douce')) return '🍠';
  if (lowerName.includes('topinambour')) return '🍠';
  if (lowerName.includes('salsifis')) return '🥕';
  if (lowerName.includes('artichaut')) return '🍆';
  if (lowerName.includes('cresson')) return '🥗';
  if (lowerName.includes('mâche')) return '🥗';
  if (lowerName.includes('pissenlit')) return '🌼';
  if (lowerName.includes('chicorée')) return '🥬';
  if (lowerName.includes('oseille')) return '🌿';
  if (lowerName.includes('roquette')) return '🥗';
  if (lowerName.includes('cardon')) return '🌿';
  if (lowerName.includes('chou-rave')) return '⚪';
  if (lowerName.includes('brocoli-rave')) return '🥦';
  if (lowerName.includes('épinette')) return '🌿';
  if (lowerName.includes('mangetout')) return '🥬';
  if (lowerName.includes('haricot vert')) return '🥗';
  if (lowerName.includes('pois mange-tout')) return '🥬';
  if (lowerName.includes('piment')) return '🌶️';
  if (lowerName.includes('poivron jaune')) return '🫑';
  if (lowerName.includes('poivron rouge')) return '🫑';
  if (lowerName.includes('chou rouge')) return '🥬';
  if (lowerName.includes('butternut')) return '🎃';

  // Fruits
  if (lowerName.includes('pomme')) return '🍎';
  if (lowerName.includes('banane')) return '🍌';
  if (lowerName.includes('fraise')) return '🍓';
  if (lowerName.includes('fruits rouges')) return '🍓';
  if (lowerName.includes('raisin')) return '🍇';
  if (lowerName.includes('orange')) return '🍊';
  if (lowerName.includes('citron')) return '🍋';
  if (lowerName.includes('datte')) return '🧆';
  if (lowerName.includes('ananas')) return '🍍';
  if (lowerName.includes('pastèque')) return '🍉';
  if (lowerName.includes('cerise')) return '🍒';
  if (lowerName.includes('kiwi')) return '🥝';
  if (lowerName.includes('poire')) return '🍐';
  if (lowerName.includes('mangue')) return '🥭';
  if (lowerName.includes('pêche')) return '🍑';
  if (lowerName.includes('prune')) return '🍑';
  if (lowerName.includes('coco')) return '🥥';
  if (lowerName.includes('framboise')) return '🍇';
  if (lowerName.includes('grenade')) return '🥭';
  if (lowerName.includes('figue')) return '🍇';
  if (lowerName.includes('avocat')) return '🥑';
  if (lowerName.includes('papaye')) return '🍈';
  if (lowerName.includes('abricot')) return '🍑';
  if (lowerName.includes('litchi')) return '🍈';
  if (lowerName.includes('nectarine')) return '🍑';
  if (lowerName.includes('goyave')) return '🍈';
  if (lowerName.includes('fruit de la passion')) return '🥭';
  if (lowerName.includes('carambole')) return '🌟';
  if (lowerName.includes('melon')) return '🍈';
  if (lowerName.includes('cassis')) return '🍇';
  if (lowerName.includes('myrtille')) return '🫐';
  if (lowerName.includes('groseille')) return '🍇';
  if (lowerName.includes('mûre')) return '🫐';
  if (lowerName.includes('coing')) return '🍏';
  if (lowerName.includes('kaki')) return '🟠';
  if (lowerName.includes('pamplemousse')) return '🍊';
  if (lowerName.includes('prunelle')) return '🍇';
  if (lowerName.includes('canneberge')) return '🍒';
  if (lowerName.includes('sureau')) return '🍇';
  if (lowerName.includes('mirabelle')) return '🍑';
  if (lowerName.includes('pomelo')) return '🍊';
  if (lowerName.includes('kumquat')) return '🍊';
  if (lowerName.includes('mangoustan')) return '🍈';
  if (lowerName.includes('tamarin')) return '🌰';
  if (lowerName.includes('durian')) return '🌳';
  if (lowerName.includes('ramboutan')) return '🍈';
  if (lowerName.includes('salak')) return '🍐';
  if (lowerName.includes('fruta do conde')) return '🍐';
  if (lowerName.includes('maracuja')) return '🥭';
  if (lowerName.includes('feijoa')) return '🥝';
  if (lowerName.includes('lucuma')) return '🍋';
  if (lowerName.includes('cherimoya')) return '🍐';
  if (lowerName.includes('longane')) return '🍇';
  if (lowerName.includes('nashi')) return '🍏';
  if (lowerName.includes('pitaya')) return '🐉';
  if (lowerName.includes('pomme cannelle')) return '🍏';
  if (lowerName.includes('ziziphus')) return '🍇';
  if (lowerName.includes('sapotille')) return '🍐';
  if (lowerName.includes('jackfruit')) return '🍈';
  if (lowerName.includes('jatoba')) return '🌰';
  if (lowerName.includes('camu camu')) return '🍇';
  if (lowerName.includes('bael')) return '🍊';
  if (lowerName.includes('aguaje')) return '🍒';
  if (lowerName.includes('maqui')) return '🫐';
  if (lowerName.includes('mamoncillo')) return '🍏';
  if (lowerName.includes('bilimbi')) return '🌿';
  if (lowerName.includes('cucamelon')) return '🥒';
  if (lowerName.includes('uchuva')) return '🍒';
  if (lowerName.includes('sapote')) return '🍈';

  // Viandes et substituts
  if (lowerName.includes('poulet')) return '🍗';
  if (lowerName.includes('boeuf')) return '🥩';
  if (lowerName.includes('porc')) return '🥓';
  if (lowerName.includes('agneau')) return '🍖';
  if (lowerName.includes('canard')) return '🦆';
  if (lowerName.includes('dinde')) return '🦃';
  if (lowerName.includes('tofu')) return '🍢';
  if (lowerName.includes('seitan')) return '🌱';
  if (lowerName.includes('tempeh')) return '🌾';
  if (lowerName.includes('œuf')) return '🥚';
  if (lowerName.includes('saucisse')) return '🌭';
  if (lowerName.includes('bacon')) return '🥓';
  if (lowerName.includes('jambon')) return '🥓';
  if (lowerName.includes('steak')) return '🥩';
  if (lowerName.includes('filet mignon')) return '🍖';
  if (lowerName.includes('escalope')) return '🍗';
  if (lowerName.includes('lapin')) return '🐇';
  if (lowerName.includes('veau')) return '🥩';
  if (lowerName.includes('bison')) return '🐃';
  if (lowerName.includes('chevreuil')) return '🦌';
  if (lowerName.includes('sanglier')) return '🐗';
  if (lowerName.includes('struthion')) return '🦤';
  if (lowerName.includes('pigeon')) return '🕊️';
  if (lowerName.includes('autruche')) return '🦤';
  if (lowerName.includes('caille')) return '🐦';
  if (lowerName.includes('poulet rôti')) return '🍗';
  if (lowerName.includes('boulette')) return '🍖';
  if (lowerName.includes('kebab')) return '🥙';
  if (lowerName.includes('merguez')) return '🌭';
  if (lowerName.includes('haggis')) return '🥩';
  if (lowerName.includes('charcuterie')) return '🥓';
  if (lowerName.includes('rôti')) return '🍖';
  if (lowerName.includes('porchetta')) return '🐖';
  if (lowerName.includes('magret')) return '🦆';
  if (lowerName.includes('tranche de dinde')) return '🦃';
  if (lowerName.includes('pâté')) return '🥫';

// Céréales et légumineuses
  if (lowerName.includes('riz')) return '🍚';
  if (lowerName.includes('quinoa')) return '🌼';
  if (lowerName.includes('avoine')) return '🌿';
  if (lowerName.includes('lentille')) return '🍂';
  if (lowerName.includes('pois chiche')) return '🥬';
  if (lowerName.includes('haricot')) return '🫘';
  if (lowerName.includes('blé')) return '🌾';
  if (lowerName.includes('pâtes')) return '🍝';
  if (lowerName.includes('orge')) return '🌾';
  if (lowerName.includes('millet')) return '🌾';
  if (lowerName.includes('sarrasin')) return '🌾';
  if (lowerName.includes('épeautre')) return '🌾';
  if (lowerName.includes('kamut')) return '🌾';
  if (lowerName.includes('farro')) return '🌾';
  if (lowerName.includes('seigle')) return '🌾';
  if (lowerName.includes('maïs')) return '🌽';
  if (lowerName.includes('teff')) return '🌾';
  if (lowerName.includes('sorgho')) return '🌾';
  if (lowerName.includes('amarante')) return '🌾';
  if (lowerName.includes('pois cassés')) return '🌱';
  if (lowerName.includes('fèves')) return '🌱';
  if (lowerName.includes('soja')) return '🌱';
  if (lowerName.includes('azuki')) return '🌱';
  if (lowerName.includes('mungo')) return '🌱';
  if (lowerName.includes('lupin')) return '🌿';
  if (lowerName.includes('fenugrec')) return '🌿';
  if (lowerName.includes('lentille corail')) return '🍂';
  if (lowerName.includes('pois pigeon')) return '🌱';
  if (lowerName.includes('gourgane')) return '🌱';
  if (lowerName.includes('quinoa rouge')) return '🌼';
  if (lowerName.includes('quinoa noir')) return '🌼';
  if (lowerName.includes('lentille verte')) return '🍂';
  if (lowerName.includes('pois de senteur')) return '🌱';
  if (lowerName.includes('haricot rouge')) return '🫘';
  if (lowerName.includes('haricot noir')) return '🫘';
  if (lowerName.includes('haricot blanc')) return '🫘';
  if (lowerName.includes('haricot pinto')) return '🫘';
  if (lowerName.includes('haricot borlotti')) return '🫘';
  if (lowerName.includes('soja jaune')) return '🌱';
  if (lowerName.includes('soja vert')) return '🌱';
  if (lowerName.includes('edamame')) return '🌱';

  // Poissons et fruits de mer
  if (lowerName.includes('poisson')) return '🐟';
  if (lowerName.includes('saumon')) return '🐠';
  if (lowerName.includes('thon')) return '🐡';
  if (lowerName.includes('sardine')) return '🐋';
  if (lowerName.includes('crevette')) return '🦐';
  if (lowerName.includes('crabe')) return '🦀';
  if (lowerName.includes('huître')) return '🥞';
  if (lowerName.includes('calmar')) return '🦑';
  if (lowerName.includes('homard')) return '🦞';
  if (lowerName.includes('coquille saint-jacques')) return '🐚';
  if (lowerName.includes('moules')) return '🦪';
  if (lowerName.includes('dorade')) return '🐟';
  if (lowerName.includes('sole')) return '🐟';
  if (lowerName.includes('turbot')) return '🐟';
  if (lowerName.includes('loup de mer')) return '🐟';
  if (lowerName.includes('maquereau')) return '🐟';
  if (lowerName.includes('rouget')) return '🐟';
  if (lowerName.includes('lotte')) return '🐟';
  if (lowerName.includes('anguille')) return '🟦';
  if (lowerName.includes('raie')) return '🦈';
  if (lowerName.includes('saint-pierre')) return '🐟';
  if (lowerName.includes('cabillaud')) return '🐟';
  if (lowerName.includes('morue')) return '🐟';
  if (lowerName.includes('hareng')) return '🐟';
  if (lowerName.includes('tilapia')) return '🐟';
  if (lowerName.includes('espadon')) return '🗡️';
  if (lowerName.includes('mérou')) return '🐟';
  if (lowerName.includes('thon rouge')) return '🐟';
  if (lowerName.includes('saumon fumé')) return '🐠';
  if (lowerName.includes('poisson-chat')) return '🐟';
  if (lowerName.includes('calmar frit')) return '🦑';
  if (lowerName.includes('tentacules')) return '🐙';
  if (lowerName.includes('abalone')) return '🐚';
  if (lowerName.includes('bigorneau')) return '🐚';
  if (lowerName.includes('palourde')) return '🦪';
  if (lowerName.includes('crustacé')) return '🦀';
  if (lowerName.includes('ormeau')) return '🐚';
  if (lowerName.includes('langouste')) return '🦞';
  if (lowerName.includes('calamar')) return '🦑';
  if (lowerName.includes('tanche')) return '🐟';
  if (lowerName.includes('ombrine')) return '🐟';
  if (lowerName.includes('requin')) return '🦈';

 // Huiles, condiments et épices
 if (lowerName.includes('huile')) return '🫒';
  if (lowerName.includes('huile de tournesol')) return '🌻';
  if (lowerName.includes('huile de colza')) return '🌼';
  if (lowerName.includes('huile de sésame')) return '🌰';
  if (lowerName.includes('huile de noix')) return '🌰';
  if (lowerName.includes('huile de coco')) return '🥥';
  if (lowerName.includes('huile de pépins de raisin')) return '🍇';
  if (lowerName.includes('huile de maïs')) return '🌽';
  if (lowerName.includes('huile de lin')) return '🌾';
  if (lowerName.includes('huile de palme')) return '🌴';
  if (lowerName.includes('huile de soja')) return '🌱';
  if (lowerName.includes('huile de cameline')) return '🌿';
  if (lowerName.includes('huile de chanvre')) return '🌿';
  if (lowerName.includes('huile de carthame')) return '🌼';
  if (lowerName.includes('huile de noisette')) return '🌰';
  if (lowerName.includes('huile de pépins de courge')) return '🎃';
  if (lowerName.includes('huile de ricin')) return '🌿';
  if (lowerName.includes('huile de moutarde')) return '🌰';
  if (lowerName.includes('huile de pépins de grenade')) return '🥭';
  if (lowerName.includes('huile de macadamia')) return '🌰';
  if (lowerName.includes('huile de carotte')) return '🥕';
  if (lowerName.includes('huile de pépins de figue de barbarie')) return '🌵';
  if (lowerName.includes('huile de nigelle')) return '🌿';
  if (lowerName.includes('huile de pépins de framboise')) return '🍇';
  if (lowerName.includes('huile de pavot')) return '🌼';
  if (lowerName.includes('huile de germe de blé')) return '🌾';
  if (lowerName.includes('huile de baobab')) return '🌳';
  if (lowerName.includes('huile de tamanu')) return '🌿';
  if (lowerName.includes('huile de fruit de la passion')) return '🥭';
  if (lowerName.includes('huile de babassu')) return '🌴';
  if (lowerName.includes('huile de pépins de cassis')) return '🍇';
  if (lowerName.includes('huile de marula')) return '🌿';
  if (lowerName.includes('huile de cranberry')) return '🍒';
  if (lowerName.includes('huile de kukui')) return '🌿';
  if (lowerName.includes('huile de pépins de melon')) return '🍈';
  if (lowerName.includes('huile de dattier du désert')) return '🌵';
  if (lowerName.includes('huile de buriti')) return '🌴';
  if (lowerName.includes('huile de figuier de Barbarie')) return '🌵';
  if (lowerName.includes('huile de rose musquée')) return '🌹';
  if (lowerName.includes('huile de chaulmoogra')) return '🌿';
  if (lowerName.includes('huile de neem')) return '🌿';
  if (lowerName.includes('huile de calophylle')) return '🌿';
  if (lowerName.includes('huile de graines de concombre')) return '🥒';
  if (lowerName.includes('huile de baies de laurier')) return '🌿';
  if (lowerName.includes('huile de fruit de la passion')) return '🥭';
  if (lowerName.includes('huile de prune')) return '🍑';
  if (lowerName.includes('huile de camomille')) return '🌼';

  if (lowerName.includes('beurre')) return '🧈';
  if (lowerName.includes('moutarde')) return '🌫';
  if (lowerName.includes('vinaigre')) return '🥧';
  if (lowerName.includes('curry')) return '🍺';
  if (lowerName.includes('paprika')) return '🍛';
  if (lowerName.includes('poivre')) return '🥛';
  if (lowerName.includes('sel')) return '🥜';
  if (lowerName.includes('safran')) return '🥨';
  if (lowerName.includes('basilic')) return '🌿';
  if (lowerName.includes('origan')) return '🌿';
  if (lowerName.includes('thym')) return '🌿';
  if (lowerName.includes('romarin')) return '🌿';
  if (lowerName.includes('ciboulette')) return '🌿';
  if (lowerName.includes('persil')) return '🌿';
  if (lowerName.includes('coriandre')) return '🌿';
  if (lowerName.includes('estragon')) return '🌿';
  if (lowerName.includes('fenouil')) return '🌿';
  if (lowerName.includes('menthe')) return '🌿';
  if (lowerName.includes('cumin')) return '🌰';
  if (lowerName.includes('curcuma')) return '🍂';
  if (lowerName.includes('gingembre')) return '🫚';
  if (lowerName.includes('clou de girofle')) return '🌰';
  if (lowerName.includes('noix de muscade')) return '🌰';
  if (lowerName.includes('cardamome')) return '🌰';
  if (lowerName.includes('cannelle')) return '🌰';
  if (lowerName.includes('piment de cayenne')) return '🌶️';
  if (lowerName.includes('piment doux')) return '🌶️';
  if (lowerName.includes('baie de genièvre')) return '🌰';
  if (lowerName.includes('anis étoilé')) return '🌟';
  if (lowerName.includes('fenugrec')) return '🌿';
  if (lowerName.includes('graines de moutarde')) return '🌰';
  if (lowerName.includes('raifort')) return '🌿';
  if (lowerName.includes('aneth')) return '🌿';
  if (lowerName.includes('mélisse')) return '🌿';
  if (lowerName.includes('sauge')) return '🌿';
  if (lowerName.includes('verveine')) return '🌿';
  if (lowerName.includes('poivre noir')) return '🧂';
  if (lowerName.includes('poivre blanc')) return '🧂';
  if (lowerName.includes('graine de fenouil')) return '🌰';
  if (lowerName.includes('graine de carvi')) return '🌰';
  if (lowerName.includes('pavot')) return '🌼';
  if (lowerName.includes('sésame')) return '🌰';
  if (lowerName.includes('curcuma moulu')) return '🍂';
  if (lowerName.includes('paprika fumé')) return '🍛';
  if (lowerName.includes('épices tandoori')) return '🍛';
  if (lowerName.includes('fleur de sel')) return '🧂';

  // Produits laitiers
  if (lowerName.includes('fromage')) return '🧀';
  if (lowerName.includes('lait')) return '🥛';
  if (lowerName.includes('yaourt')) return '🍶';
  if (lowerName.includes('crème')) return '🍶';
  if (lowerName.includes('beurre')) return '🧈';
  if (lowerName.includes('fromage bleu')) return '🧀';
  if (lowerName.includes('fromage de chèvre')) return '🐐';
  if (lowerName.includes('fromage fondu')) return '🧀';
  if (lowerName.includes('fromage à pâte molle')) return '🧀';
  if (lowerName.includes('fromage à pâte dure')) return '🧀';
  if (lowerName.includes('lait entier')) return '🥛';
  if (lowerName.includes('lait écrémé')) return '🥛';
  if (lowerName.includes('lait demi-écrémé')) return '🥛';
  if (lowerName.includes('lait de chèvre')) return '🐐';
  if (lowerName.includes('lait de brebis')) return '🐑';
  if (lowerName.includes('lait de bufflonne')) return '🐃';
  if (lowerName.includes('lait de soja')) return '🌱';
  if (lowerName.includes('lait d avoine')) return '🌾';
  if (lowerName.includes('lait d amande')) return '🌰';
  if (lowerName.includes('lait de noisette')) return '🌰';
  if (lowerName.includes('lait de coco')) return '🥥';
  if (lowerName.includes('crème fraîche')) return '🥛';
  if (lowerName.includes('crème épaisse')) return '🥛';
  if (lowerName.includes('crème liquide')) return '🥛';
  if (lowerName.includes('crème chantilly')) return '🍦';
  if (lowerName.includes('mascarpone')) return '🧀';
  if (lowerName.includes('ricotta')) return '🧀';
  if (lowerName.includes('fromage blanc')) return '🧀';
  if (lowerName.includes('fromage frais')) return '🧀';
  if (lowerName.includes('fromage cottage')) return '🧀';
  if (lowerName.includes('féta')) return '🧀';
  if (lowerName.includes('mozzarella')) return '🧀';
  if (lowerName.includes('parmesan')) return '🧀';
  if (lowerName.includes('gorgonzola')) return '🧀';
  if (lowerName.includes('gruyère')) return '🧀';
  if (lowerName.includes('emmental')) return '🧀';
  if (lowerName.includes('brie')) return '🧀';
  if (lowerName.includes('camembert')) return '🧀';
  if (lowerName.includes('roquefort')) return '🧀';
  if (lowerName.includes('reblochon')) return '🧀';
  if (lowerName.includes('pont-l évêque')) return '🧀';
  if (lowerName.includes('saint-nectaire')) return '🧀';
  if (lowerName.includes('tomme')) return '🧀';
  if (lowerName.includes('munster')) return '🧀';
  if (lowerName.includes('chèvre frais')) return '🐐';
  if (lowerName.includes('chèvre sec')) return '🐐';
  if (lowerName.includes('lait fermenté')) return '🥛';
  if (lowerName.includes('lait ribot')) return '🥛';
  if (lowerName.includes('lait concentré')) return '🥛';
  if (lowerName.includes('lait évaporé')) return '🥛';
  if (lowerName.includes('lait en poudre')) return '🥛';
  if (lowerName.includes('lait de poule')) return '🥚';
  if (lowerName.includes('kéfir')) return '🥛';

  // Boissons
  if (lowerName.includes('café')) return '☕';
  if (lowerName.includes('thé')) return '🍵';
  if (lowerName.includes('jus')) return '🥤';
  if (lowerName.includes('vin')) return '🍷';
  if (lowerName.includes('bière')) return '🍻';

  // Sucreries et desserts
  if (lowerName.includes('chocolat')) return '🍫';
  if (lowerName.includes('gâteau')) return '🍰';
  if (lowerName.includes('glace')) return '🍦';
  if (lowerName.includes('bonbon')) return '🍬';
  if (lowerName.includes('biscuit')) return '🍪';
  if (lowerName.includes('tarte')) return '🍧';

  // Fruits à coque et graines
  if (lowerName.includes('amande')) return '🌰';
  if (lowerName.includes('noisette')) return '🌰';
  if (lowerName.includes('noix')) return '🌰';
  if (lowerName.includes('cacahuète')) return '🥜';
  if (lowerName.includes('pistache')) return '🥜';
  if (lowerName.includes('noix de cajou')) return '🥜';
  if (lowerName.includes('noix de macadamia')) return '🌰';
  if (lowerName.includes('graines de tournesol')) return '🌻';
  if (lowerName.includes('graines de courge')) return '🎃';
  if (lowerName.includes('graines de sésame')) return '🌰';
  if (lowerName.includes('noix du Brésil')) return '🌰';
  if (lowerName.includes('pignon de pin')) return '🌲';
  if (lowerName.includes('graines de lin')) return '🌾';
  if (lowerName.includes('graines de chia')) return '🌾';
  if (lowerName.includes('graines de pavot')) return '🌼';
  if (lowerName.includes('coco râpé')) return '🥥';
  if (lowerName.includes('châtaigne')) return '🌰';
  if (lowerName.includes('noix de pécan')) return '🌰';
  if (lowerName.includes('graine de chanvre')) return '🌿';
  if (lowerName.includes('graines de carvi')) return '🌱';
  if (lowerName.includes('graine de fenouil')) return '🌿';
  if (lowerName.includes('graines de moutarde')) return '🌿';
  if (lowerName.includes('graine de courge')) return '🎃';
  if (lowerName.includes('graines de nigelle')) return '🌿';
  if (lowerName.includes('noix de kola')) return '🌰';
  if (lowerName.includes('graine de tournesol')) return '🌻';
  if (lowerName.includes('pistaches salées')) return '🥜';
  if (lowerName.includes('graines de pastèque')) return '🍉';
  if (lowerName.includes('amandes effilées')) return '🌰';
  if (lowerName.includes('noix hachées')) return '🌰';
  if (lowerName.includes('graines de cardamome')) return '🌿';
  if (lowerName.includes('noix confites')) return '🍬';
  if (lowerName.includes('poudre de noisette')) return '🌰';
  if (lowerName.includes('graines de cumin')) return '🌿';
  if (lowerName.includes('graines de fenugrec')) return '🌿';
  if (lowerName.includes('graines de coriandre')) return '🌿';
  if (lowerName.includes('graine de pavot bleu')) return '🌼';
  if (lowerName.includes('graines de roquette')) return '🌿';
  if (lowerName.includes('graines de sarrasin')) return '🌾';

  // Divers
  if (lowerName.includes('pain')) return '🍞';
  if (lowerName.includes('muesli')) return '🥣';
  if (lowerName.includes('miel')) return '🍯';
  if (lowerName.includes('pizza')) return '🍕';
  if (lowerName.includes('soupe')) return '🥣';
  if (lowerName.includes('sushi')) return '🍣';
  if (lowerName.includes('croissant')) return '🥐';
  if (lowerName.includes('baguette')) return '🥖';
  if (lowerName.includes('tortilla')) return '🥟';

  // Emoji par défaut
  return '🍽';
};
