import React from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { Facebook, Instagram, Mail, Phone, Twitter } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function AboutPage() {
  return (
    <>
      <Helmet>
        <title>Yoco - À propos</title>
        <meta name="description" content="Découvrez l'histoire et la mission de Yoco, votre partenaire pour la performance physique et mentale." />
        <meta name="keywords" content="Yoco, mission, performance, dépassement de soi" />
      </Helmet>
    <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', width: { xs: '-webkit-fill-available', md: '100%' }, margin: '0 auto' }}>

      {/* Hero Section */}
      <Box sx={{ mt: 6, mb: 4, display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' }, gap: 4 }}>
      <Box sx={{ flex: 1 }}>
          <Typography textTransform="uppercase" variant="h1" sx={{ mb: 2 }}>
            We are Yoco !
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.5em' }}>
            La réussite n'est jamais le fruit d'un effort individuel. Chez Yoco, chaque projet est pensé comme une aventure collective, où chacun apporte sa valeur.
          </Typography>
        </Box>
        <Box sx={{ flex: 1, textAlign: { xs: 'center', md: 'right' }, mt: { xs: 4, md: 0 } }}>
          <img src="/static/about-image.jpg" alt="About Yoco" style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: 4 }} />
        </Box>

      </Box>

      {/* About Section */}
      <Box sx={{  mb: 6 }}>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Yoco, c'est l'équilibre entre rigueur et intuition, entre discipline et adaptabilité. Parce que chaque objectif demande une approche unique, nous savons associer la précision d’un plan structuré à la flexibilité nécessaire pour s'adapter aux défis.
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Née d’une passion pour le sport et le dépassement de soi, Yoco s’appuie sur des années d'expérience sur le terrain, du joueur à l'éducateur, du soldat du feu issu des pompiers de Paris au préparateur physique.
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Cette diversité nous a appris que la réussite repose avant tout sur la capacité à allier des qualités opposées : être exigeant sans être rigide, compétitif mais toujours à l'écoute.
        </Typography>
      </Box>

      {/* Founder Section */}
      <Box sx={{ mt: 8, display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' }, gap: 4 }}>
        <Box sx={{ flex: 1 }}>
          <img src="/static/thomas.jpg" alt="Thomas Fernandez" style={{ width: '100%', maxWidth: '400px', height: 'auto', objectFit: 'cover', borderRadius: 4 }} />
        </Box>
        <Box sx={{ flex: 2 }}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Thomas Fernandez
          </Typography>
          <Typography variant="body1">
            Née d’une passion pour le sport et le dépassement de soi, Thomas est le fondateur de Yoco. Avec une expérience riche, allant des pompiers de Paris au coaching physique, Thomas incarne les valeurs d’exigence et d’adaptabilité qui sont au cœur de Yoco.
          </Typography>
        </Box>
      </Box>

      {/* Mission Section */}
      <Box sx={{ mt: 6}}>
      <Typography variant="h2" sx={{ mb: 2}}>
          Notre mission
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Offrir un accompagnement complet qui équilibre le corps et l’esprit, afin que chacun puisse atteindre ses objectifs et découvrir ce qu’il a de meilleur en lui.
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Qu’il s’agisse de coaching sportif personnalisé, de programmes de nutrition optimisés, ou d’événements de teambuilding ou de formation pour renforcer l'esprit de corps, Yoco est là pour vous guider, vous inspirer et vous pousser à aller plus loin.
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Parce que l'équilibre est la clé du succès, et que chaque victoire commence par une préparation solide et un mental à toute épreuve.
        </Typography>
      </Box>

      {/* Call to Action Section */}
      <Box sx={{ backgroundColor: '#000', color: '#fff', width: '100%', py: 5, textAlign: 'center', borderRadius: 2, mt:8 }}>
        <Typography variant="h2" sx={{ mb: 3 }}>
          Prêt à nous rejoindre ?
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '1.1em', mb: 4, px: { xs: 2, sm: 15 } }}>
          Contactez-nous dès aujourd'hui et faites le premier pas vers l'atteinte de vos objectifs.
        </Typography>
        <Button variant="contained" component={Link} to="/contact" color="primary" size="large">
          Contactez-nous
        </Button>
      </Box>

      {/* Social Media Section */}
      <Box sx={{ mt: 5, textAlign: 'center', py: 3, width: '100%' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Suivez-nous sur les réseaux sociaux
        </Typography>
        <Box>
          <IconButton color="inherit" sx={{ color: '#000' }}>
            <Facebook />
          </IconButton>
          <IconButton color="inherit" sx={{ color: '#000' }}>
            <Instagram />
          </IconButton>
          <IconButton color="inherit" sx={{ color: '#000' }}>
            <Twitter />
          </IconButton>
          <IconButton color="inherit" component={Link} to="/contact" sx={{ color: '#000' }}>
            <Mail />
          </IconButton>
          <IconButton color="inherit" sx={{ color: '#000' }}>
            <Phone />
          </IconButton>
        </Box>
      </Box>
    </Box>
    </>
  );
}

export default AboutPage;