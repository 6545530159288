import React, { useState } from 'react';
import { Box, TextField, Button, Alert, Typography, Container } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/forgot-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        const data = await response.json();
        if (response.ok) {
            setMessage(data.message);
        } else {
            if (response.status === 404) {
                setError('Aucun utilisateur trouvé avec cet email. Veuillez vérifier votre adresse.');
            } else {
                setError(data.message || "Une erreur s'est produite. Veuillez réessayer.");
            }
        }
    } catch (err) {
        setError("Impossible de se connecter au serveur. Veuillez réessayer plus tard.");
    }
};


  return (
    <Container sx={{ minHeight:'70vh', display: 'flex', alignItems: 'center', flexDirection:'column', justifyContent:'center' }} maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 8,
          py: 4,
          px: { xs: 2, sm: 4 },
          borderRadius: 2,
          border: '1px solid #DDD',
          backgroundColor: 'white',
        }}
      >
        <Typography textAlign="center" variant="h3" sx={{ mb: 0 }}>
          Réinitialisation du mot de passe
        </Typography>
        {message && <Alert severity="success" sx={{ mt: 2, width: '100%' }}>{message}</Alert>}
        {error && <Alert severity="error" sx={{ mt: 2, width: '100%' }}>{error}</Alert>}
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, width: '100%' }}>
          <TextField
            label="Email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <Button type="submit" variant="contained" fullWidth sx={{ mt: 0, mb: 1 }}>
            Envoyer un lien
          </Button>
        </Box>
      </Box>
      <Button
          startIcon={<ArrowBackIcon />}
          variant="text"
          fullWidth
          color='black'
          onClick={() => window.history.back()}
          sx={{ mt: 1.5 }}
        >
          Retour
        </Button>
    </Container>
  );
};

export default ForgotPassword;