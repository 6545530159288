import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';
import { Helmet } from 'react-helmet-async';

const ContactPage = () => {
  const [formData, setFormData] = useState({ firstName: '', lastName: '', email: '', message: '' });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/emails/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage('Votre message a bien été envoyé !');
        setFormData({ firstName: '', lastName: '', email: '', message: '' });
      } else {
        throw new Error("Une erreur s'est produite");
      }
    } catch (error) {
      setErrorMessage("Une erreur s'est produite, veuillez réessayer plus tard.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Yoco - Contact</title>
        <meta name="description" content="Besoin d'informations ou d'accompagnement ? Contactez l'équipe Yoco dès aujourd'hui." />
        <meta name="keywords" content="Yoco, contact, coaching, support" />
      </Helmet>
      <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', width: { xs: '-webkit-fill-available', md: '100%' }, margin: '0 auto', minHeight: '72vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Typography textTransform="uppercase" variant="h1" sx={{ mb: 0, textAlign:'center' }}>
        À votre écoute
      </Typography>
      {successMessage && <Alert severity="success" sx={{ mb: 3 }}>{successMessage}</Alert>}
      {errorMessage && <Alert severity="error" sx={{ mb: 3 }}>{errorMessage}</Alert>}
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 3, backgroundColor: 'rgba(255, 255, 255, 0.8)', p: 4, borderRadius: 2 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            label="Prénom"
            variant="outlined"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            sx={{ flex: 1 }}
          />
          <TextField
            label="Nom"
            variant="outlined"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
            sx={{ flex: 1 }}
          />
        </Box>
        <TextField
          label="Email"
          variant="outlined"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <TextField
          label="Message"
          variant="outlined"
          name="message"
          value={formData.message}
          onChange={handleChange}
          multiline
          rows={4}
          required
        />
        <Button type="submit" variant="contained" color="primary" size="large">
          Envoyer
        </Button>
      </Box>
    </Box>
    </>
  );
};

export default ContactPage;
