import React from 'react';
import { Box, Typography, Chip } from '@mui/material';

const DIETARY_TRANSLATIONS = {
  vegetarian: "Végétarien",
  vegan: "Vegan",
  "gluten-free": "Sans gluten",
  "dairy-free": "Sans produits laitiers",
  paleo: "Paléo",
  keto: "Kéto",
  other: "Autre",
};

const UserInformation = ({ selectedClient, userPreferences }) => {
  return (
    <Box sx={{ border: '1px solid #DDD', borderRadius: 1, mb: 2, p: 2 }}>
      {selectedClient && userPreferences ? (
        <>
          <Typography sx={{ mb: 1 }} variant="h5">
            {selectedClient.firstName} {selectedClient.lastName}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <Typography variant="body2">
              <strong>Objectif</strong> <Chip label={selectedClient.goals} />
            </Typography>
            <Typography sx={{ mx: 4 }} variant="body2">
              <strong>Régime alimentaire</strong>{" "}
              <Chip
                label={
                  userPreferences.length > 0
                    ? userPreferences
                        .map((pref) => DIETARY_TRANSLATIONS[pref] || pref)
                        .join(", ")
                    : "Aucun"
                }
              />
            </Typography>
            {selectedClient.allergies && selectedClient.allergies.length > 0 && (
              <Typography variant="body2">
                <strong>Allergies</strong>{" "}
                <Chip label={selectedClient.allergies.join(", ")} />
              </Typography>
            )}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            minHeight: "75px",
            alignItems: "center",
          }}
        >
          <Typography textTransform="uppercase" variant="body2">
            Aucun client sélectionné
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default UserInformation;
