import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, CircularProgress, Stack, Button, IconButton, Paper } from '@mui/material';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Helmet } from 'react-helmet-async';

const Dashboard = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [weekPlans, setWeekPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
        const recipeResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/recipes`);
        const weekPlanResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/weekplans/admin`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (!userResponse.ok || !recipeResponse.ok || !weekPlanResponse.ok) {
          throw new Error('Failed to fetch data');
        }

        const userData = await userResponse.json();
        const recipeData = await recipeResponse.json();
        const weekPlanData = await weekPlanResponse.json();

        setUsers(userData);
        setRecipes(recipeData);
        setWeekPlans(weekPlanData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6" color="error">{error}</Typography>
      </Box>
    );
  }

  const userStats = {
    totalUsers: users.length,
    activeUsers: users.filter(user => user.active).length,
  };

  const recipeStats = {
    totalRecipes: recipes.length,
  };

  const weekPlanStats = {
    totalWeekPlans: weekPlans.length,
  };

  const activeUser = users.find(user => user.active);

  return (
    <>
      <Helmet>
        <title>Yoco - Dashboard</title>
        <meta name="keywords" content="coaching, sport, nutrition, bien-être, performance" />
      </Helmet>
      <Header />
    <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', width: { xs: '-webkit-fill-available', md: '100%' }, margin: '0 auto' }}>
        <Typography variant="h2" sx={{ mb: 2 }}>Bonjour {activeUser ? activeUser.firstName : ''}</Typography>
        <Stack direction="row" sx={{ mb: 5 }} spacing={1}>
          <Button variant="contained" color="primary" onClick={() => navigate('/users/create')} startIcon={<AddCircleIcon />}>client</Button>
          <Button variant="contained" color="primary" onClick={() => navigate('/recipes/create')} startIcon={<AddCircleIcon />}>recette</Button>
          <Button variant="contained" color="primary" onClick={() => navigate('/plans/create')} startIcon={<AddCircleIcon />}>programme</Button>
        </Stack>
        <Grid container spacing={3}>
          {/* KPI Cards */}
          <Grid item xs={12} md={4}>
            <Card elevation="0" sx={{ border: '1px solid #DDD', px: 1, pt: 1, pb: 0 }}>
              <CardContent>
                <Typography variant="h1">{userStats.totalUsers}</Typography>
                <Typography variant="h6">Clients</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card elevation="0" sx={{ border: '1px solid #DDD', px: 1, pt: 1, pb: 0 }}>
              <CardContent>
                <Typography variant="h1">{recipeStats.totalRecipes}</Typography>
                <Typography variant="h6">Recettes</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card elevation="0" sx={{ border: '1px solid #DDD', px: 1, pt: 1, pb: 0 }}>
              <CardContent>
                <Typography variant="h1">{weekPlanStats.totalWeekPlans}</Typography>
                <Typography variant="h6">Programmes</Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Active Users List */}
          <Grid item xs={12}>
            <Paper elevation={0} sx={{ border: '1px solid #DDD', px:3, py:3 }}>
              <Typography variant="h5" sx={{ mb: 1.5 }}  gutterBottom>Clients en cours</Typography>
              <Stack spacing={2}>
                {users.filter(user => user.active).map((user) => (
                  <Box key={user._id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px:2, py: 1, borderRadius: 1, backgroundColor: '#f9f9f9' }}>
                    <Typography variant="body1">{user.firstName} {user.lastName}</Typography>
                    <IconButton onClick={() => navigate(`/users/view/${user._id}`)}>
                      <KeyboardArrowRightIcon color="black" />
                    </IconButton>
                  </Box>
                ))}
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
