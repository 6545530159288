import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  Avatar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Drawer,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CloseIcon from '@mui/icons-material/Close';
import { AuthContext } from '../../context/AuthContext';

const animalEmojis = ['🐶', '🐱', '🐭', '🦊', '🐻', '🐼', '🐷', '🐸', '🐵', '🦁', '🐯', '🐨', '🦄'];

const Comments = ({ planId }) => {
  const { user } = useContext(AuthContext); // Récupération de l'utilisateur connecté
  const currentUserId = user?.id; // ID de l'utilisateur connecté

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState({ open: false, commentId: null });
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Fonction utilitaire pour formater la date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) return 'Aujourd’hui';
    if (date.toDateString() === yesterday.toDateString()) return 'Hier';
    return date.toLocaleDateString();
  };

  // Fonction pour obtenir un emoji basé sur un identifiant utilisateur
  const getEmojiForUser = (userId) => {
    const index = userId
      ? Array.from(userId).reduce((acc, char) => acc + char.charCodeAt(0), 0) % animalEmojis.length
      : Math.floor(Math.random() * animalEmojis.length);
    return animalEmojis[index];
  };

  // Fetch comments when the component mounts
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/weekplans/${planId}/comments`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (!response.ok) {
          if (response.status === 403) throw new Error("Vous n'avez pas la permission de voir ces commentaires.");
          throw new Error("Erreur lors de la récupération des commentaires.");
        }

        const data = await response.json();
        setComments(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [planId]);

  // Ajouter un commentaire
  const addComment = async () => {
    if (!newComment.trim()) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/weekplans/${planId}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ text: newComment }),
      });

      if (!response.ok) throw new Error("Erreur lors de l'ajout du commentaire.");

      const updatedComments = await response.json();
      if (Array.isArray(updatedComments)) {
        setComments(updatedComments);
        setNewComment('');
      } else {
        throw new Error('Les données retournées ne sont pas valides.');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  // Gérer la suppression d’un commentaire
  const deleteComment = async () => {
    try {
      const { commentId } = deleteDialog;
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/weekplans/${planId}/comments/${commentId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );

      if (!response.ok) throw new Error('Erreur lors de la suppression du commentaire.');

      setComments((prev) => prev.filter((comment) => comment._id !== commentId));
      setDeleteDialog({ open: false, commentId: null });
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Box>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Box sx={{ mt:1.5 }}>
          {Array.isArray(comments) &&
            comments.slice(-3).map((comment) => (
              <Box
                key={comment._id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mb: 1,
                  p: 1.5,
                  border: '1px solid #ddd',
                  borderRadius: '8px',
                  backgroundColor: '#f9f9f9',
                }}
              >
                <Avatar>{getEmojiForUser(comment.user?._id || '')}</Avatar>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body2" color="black">
                    <Typography component="span" variant="body2" sx={{ fontWeight: 'bold' }}>
                      {comment.user?.firstName || 'Utilisateur inconnu'} {comment.user?.lastName || ''}{' '}
                    </Typography>
                    <Typography component="span" sx={{ fontSize: '0.8em', color: 'gray' }}>
                      {formatDate(comment.createdAt)}
                    </Typography>
                  </Typography>
                  <Typography variant="body2">{comment.text}</Typography>
                </Box>
                {comment.user?._id === currentUserId && ( // Bouton supprimer uniquement pour l'utilisateur connecté
                  <IconButton
                    onClick={() => setDeleteDialog({ open: true, commentId: comment._id })}
    
                  >
                    <DeleteIcon sx={{ color: '#b3b3b3', fontSize: '0.8em' }} />
                  </IconButton>
                )}
              </Box>
            ))}

          {comments.length > 3 && (
            <Button
              onClick={() => setDrawerOpen(true)}
              sx={{ textTransform: 'none', color: 'gray', mt: 0, mb:1, width:'100%', border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#f9f9f9', fontSize:'0.8em'}}
            >
              Voir tous les commentaires
            </Button>
          )}
        </Box>
      )}

      <Box>
        <TextField
          fullWidth
          variant="standard"
          placeholder="Ajouter un commentaire..."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton onClick={addComment} color="black">
                <SendRoundedIcon sx={{ fontSize: '0.8em' }} />
              </IconButton>
            ),
          }}
          sx={{
            '& .MuiInputBase-input': { fontSize: '0.85em' },
            '& .MuiInputBase-input::placeholder': { fontSize: '1em' },
            fontSize: '0.85em',
          }}
        />
      </Box>

      {/* Drawer pour afficher tous les commentaires */}
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box sx={{ width: 350, p: 2, pt:5, position: 'relative' }}>
          {/* Bouton de fermeture */}
          <Box sx={{ position: 'absolute', top: '5px', left: '5px' }}>
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography sx={{ mb:1 }} variant="h6">Tous les commentaires</Typography>
          {comments.map((comment) => (
            <Box
              key={comment._id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                mb: 1,
                p: 1.5,
                border: '1px solid #ddd',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
              }}
            >
              <Avatar>{getEmojiForUser(comment.user?._id || '')}</Avatar>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" color="black">
                  <Typography component="span" variant="body2" sx={{ fontWeight: 'bold' }}>
                    {comment.user?.firstName || 'Utilisateur inconnu'} {comment.user?.lastName || ''}{' '}
                  </Typography>
                  <Typography component="span" sx={{ fontSize: '0.8em', color: 'gray' }}>
                    {formatDate(comment.createdAt)}
                  </Typography>
                </Typography>
                <Typography variant="body2">{comment.text}</Typography>
              </Box>
              {comment.user?._id === currentUserId && ( // Bouton supprimer uniquement pour l'utilisateur connecté
                <IconButton
                  onClick={() => setDeleteDialog({ open: true, commentId: comment._id })}
                >
                  <DeleteIcon sx={{ color: '#b3b3b3', fontSize: '0.7em' }} />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
      </Drawer>

      {/* Popin de confirmation */}
      <Dialog open={deleteDialog.open} onClose={() => setDeleteDialog({ open: false, commentId: null })}>
        <DialogTitle>Supprimer le commentaire</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer ce commentaire ? Cette action est irréversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog({ open: false, commentId: null })} color="grey">
            Annuler
          </Button>
          <Button onClick={deleteComment} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Comments;
