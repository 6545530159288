// Recommendations.js - Component for Recommendations
import React from 'react';
import { TextField, Button, Box } from '@mui/material';
import UserMin from '../../components/plans/UserMin';

const translations = {
  sleep: 'Sommeil',
  alcohol: 'Alcool',
  dairyProducts: 'Produits laitiers',
  hydration: 'Hydratation',
  physicalActivity: 'Activité physique',
  stressManagement: 'Gestion du stress',
  nutrition: 'Nutrition',
  others: 'Autres',
};

const Recommendations = ({ recommendations, setRecommendations, handleCreateWeekPlan, prevStep, selectedClient, userPreferences }) => (
  <>

    <UserMin selectedClient={selectedClient} userPreferences={userPreferences} />
    
    {Object.keys(recommendations).map((recKey) => (
      <TextField
        key={recKey}
        label={translations[recKey] || recKey.charAt(0).toUpperCase() + recKey.slice(1)}
        fullWidth
        multiline
        rows={2}
        value={recommendations[recKey]}
        onChange={(e) => setRecommendations({ ...recommendations, [recKey]: e.target.value })}
        sx={{ marginBottom: 2 }}
      />
    ))}

    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button variant="contained" color="secondary" onClick={prevStep} sx={{ marginRight: 2 }}>
        Retour
      </Button>
      <Button variant="contained" color="primary" onClick={handleCreateWeekPlan}>
        {window.location.pathname.includes('edit') ? 'Sauvegarder' : 'Créer le programme'}
      </Button>
    </Box>
  </>
);

export default Recommendations;
