import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, MenuItem, Box, Typography, Paper, Drawer, Stack, List, Dialog, DialogTitle, DialogActions, ListItem, IconButton, Card, CardContent, CardActions, Alert, Collapse } from '@mui/material';
import { Search as SearchIcon, Add as AddIcon, ArrowBack as ArrowBackIcon, Delete as DeleteIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import Header from '../../components/Header'; 

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const RecipeForm = () => {
  const [recipe, setRecipe] = useState({
    name: '',
    mealType: '',
    category: '',
    preparationTime: 0,
    cookingTime: 0,
    ingredients: [],
    instructions: '',
    macros: {
      calories: 0,
      carbohydrates: 0,
      proteins: 0,
      fats: 0
    }
  });
  const navigate = useNavigate();
  const [ingredientList, setIngredientList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [quantity, setQuantity] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  useEffect(() => {
    fetch('/ciqual.json')
      .then(response => response.json())
      .then(data => setIngredientList(data));
  }, []);

    // Fonction utilitaire pour parser les valeurs nutritionnelles
    const parseNutrientValue = (value) => {
        if (typeof value === 'string') {
          value = value.replace(',', '.'); // Remplacer la virgule par un point
          if (value.includes('<') || value === 'traces' || value === '-' || value === '') {
            return 0; // Remplacer les valeurs non numériques ou manquantes par zéro
          }
        }
        return parseFloat(value) || 0;
      };      

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRecipe({ ...recipe, [name]: value });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
    setSearchTerm(''); // Réinitialise la recherche quand on ouvre le drawer
  };

  const handleIngredientSelect = (ingredient) => {
    setSelectedIngredient(ingredient);
    setSearchTerm(ingredient.alim_nom_fr); // Définir le terme de recherche sur l'ingrédient sélectionné
    setQuantity(''); // Réinitialiser la quantité après la sélection d'un nouvel ingrédient
  };
  
  const filteredIngredients = ingredientList.filter(ingredient =>
    ingredient.alim_nom_fr.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const addIngredient = () => {
    if (selectedIngredient && quantity) {
      setRecipe({
        ...recipe,
        ingredients: [
          ...recipe.ingredients,
          { name: selectedIngredient.alim_nom_fr, ciqual_code: selectedIngredient.alim_code, quantity: parseFloat(quantity) }
        ]
      });
      setSelectedIngredient(null); // Réinitialiser la sélection après l'ajout
      setQuantity('');
      setDrawerOpen(false);
    }
  };

  const deleteIngredient = (indexToDelete) => {
    setRecipe({
      ...recipe,
      ingredients: recipe.ingredients.filter((_, index) => index !== indexToDelete)
    });
  };

  const calculateMacros = () => {
    let totalCalories = 0;
    let totalCarbs = 0;
    let totalProteins = 0;
    let totalFats = 0;
  
    recipe.ingredients.forEach(ingredient => {
      const ciqualData = ingredientList.find(item => item.alim_code === ingredient.ciqual_code);
      
      // Debug: Log pour vérifier si l'ingrédient est trouvé
      console.log(`Ingrédient : ${ingredient.name}`);
      if (ciqualData) {
        console.log(`Données CIQUAL trouvées pour ${ingredient.name}`, ciqualData);
  
        const quantityInGrams = ingredient.quantity;
  
        // Log des valeurs nutritionnelles trouvées avant calcul
        console.log('Valeurs avant parsing:',
          ciqualData["Energie, Règlement UE N° 1169/2011 (kcal/100 g)"],
          ciqualData["Glucides (g/100 g)"],
          ciqualData["Protéines, N x facteur de Jones (g/100 g)"],
          ciqualData["Lipides (g/100 g)"]
        );
  
        totalCalories += (parseNutrientValue(ciqualData["Energie, Règlement UE N° 1169/2011 (kcal/100 g)"]) * quantityInGrams) / 100;
        totalCarbs += (parseNutrientValue(ciqualData["Glucides (g/100 g)"]) * quantityInGrams) / 100;
        totalProteins += (parseNutrientValue(ciqualData["Protéines, N x facteur de Jones (g/100 g)"]) * quantityInGrams) / 100;
        totalFats += (parseNutrientValue(ciqualData["Lipides (g/100 g)"]) * quantityInGrams) / 100;
  
        // Log des valeurs après calcul
        console.log(`Calories totales : ${totalCalories}`);
        console.log(`Glucides totaux : ${totalCarbs}`);
        console.log(`Protéines totales : ${totalProteins}`);
        console.log(`Lipides totaux : ${totalFats}`);
      } else {
        console.error(`Aucune donnée CIQUAL trouvée pour l'ingrédient : ${ingredient.name}`);
      }
    });
  
    setRecipe({
      ...recipe,
      macros: {
        calories: totalCalories,
        carbohydrates: totalCarbs,
        proteins: totalProteins,
        fats: totalFats
      }
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/api/recipes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(recipe),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.errors) {
          const errorMessages = Object.entries(errorData.errors)
            .map(([field, message]) => {
              switch (field) {
                case 'name':
                  return "Le nom de la recette est requis.";
                case 'mealType':
                  return "Le type de repas est requis.";
                case 'instructions':
                  return "Les instructions de préparation sont requises.";
                case 'category.0':
                  return "La catégorie fournie n'est pas valide.";
                default:
                  return message;
              }
            })
            .join(' ');
          throw new Error(errorMessages);
        } else {
          throw new Error(errorData.message);
        }
      }
  
      setSuccessModalOpen(true); // Ouvre la popin de succès
    } catch (error) {
      console.error('Erreur lors de la sauvegarde de la recette:', error);
      setErrorMessage(`Erreur lors de la sauvegarde de la recette : ${error.message}`);
      setShowAlert(true);
    }
  };
  
  
  return (
    <>

    <Header /> 

    <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', width: { xs: '-webkit-fill-available', md: '100%' }, margin: '0 auto' }}>

      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Button color='black' variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => navigate('/recipes')}>
          Retour
        </Button>
      </Stack>

      <Paper elevation={0} sx={{ p: { xs: 2, sm: 4 }, border:'1px solid #DDD' }}> 

      <Typography  sx={{ mb:2 }} variant="h4">Créer une recette</Typography>

      <TextField
        label="Nom de la recette"
        name="name"
        value={recipe.name}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Type de repas"
        name="mealType"
        value={recipe.mealType}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
        select
      >
        <MenuItem value="breakfast">Petit-déjeuner</MenuItem>
        <MenuItem value="lunch">Déjeuner</MenuItem>
        <MenuItem value="dinner">Dîner</MenuItem>
        <MenuItem value="snack">Encas</MenuItem>
      </TextField>
      <TextField
        label="Catégorie"
        name="category"
        value={recipe.category}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
        select
      >
        <MenuItem value="vegetarian">Vegetarien</MenuItem>
        <MenuItem value="vegan">Vegan</MenuItem>
        <MenuItem value="gluten-free">Sans gluten</MenuItem>
        <MenuItem value="dairy-free">Sans produits laitiers</MenuItem>
        <MenuItem value="paleo">Paléo</MenuItem>
        <MenuItem value="keto">Kéto</MenuItem>
        <MenuItem value="other">Autre</MenuItem>
      </TextField>
      <TextField
        label="Temps de préparation (min)"
        name="preparationTime"
        value={recipe.preparationTime}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Temps de cuisson (min)"
        name="cookingTime"
        value={recipe.cookingTime}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
      />

      {/* Bouton pour ouvrir le drawer de sélection des ingrédients */}
      <Button  sx={{ width:'100%', boxShadow:'none' }} variant="contained" color="secondary" onClick={toggleDrawer(true)} startIcon={<AddIcon />}>
        Ajouter un ingredient
      </Button>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 300, p: 2 }}>
          <Typography variant="h5">Ingrédients</Typography>
          <TextField
            label="Rechercher"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            sx={{ mb: 2, mt:2 }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
          <List>
            {filteredIngredients.map(ingredient => (
              <ListItem
                button
                key={ingredient.alim_code}
                onClick={() => handleIngredientSelect(ingredient)}
                selected={selectedIngredient?.alim_code === ingredient.alim_code}
              >
                {ingredient.alim_nom_fr}
                {selectedIngredient?.alim_code === ingredient.alim_code && (
                  <CheckCircleIcon sx={{ ml: 2, color: 'green' }} />
                )}
              </ListItem>
            ))}
          </List>
          <TextField
            label="Quantité (g)"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
            disabled={!selectedIngredient} // Désactiver tant qu'aucun ingrédient n'est sélectionné
          />
          <Button
            variant="contained"
            onClick={addIngredient}
            sx={{ mt: 2, width:'100%', boxShadow:'none' }}
            disabled={!selectedIngredient || !quantity} // Désactiver tant qu'aucun ingrédient ou quantité n'est fourni
          >
            Ajouter
          </Button>
        </Box>
      </Drawer>

      {/* Liste des ingrédients ajoutés, affichés sous forme de cartes */}
      <Box sx={{ mt: 4, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {recipe.ingredients.map((ingredient, index) => (
          <Card key={index} sx={{ width: 250, border:'1px solid #DDD' }} elevation="0" >
            <CardContent sx={{ p:0, px:2, pt:2 }} >
              <Typography sx={{ mb:2, lineHeight:'1.2em' }} variant="h6">{ingredient.name}</Typography>
              <Typography variant="body2">Quantité : {ingredient.quantity}g</Typography>
              <Typography variant="body2">CIQUAL Code : {ingredient.ciqual_code}</Typography>
            </CardContent>
            <CardActions>
              <IconButton aria-label="delete" onClick={() => deleteIngredient(index)}>
                <DeleteIcon />
              </IconButton>
            </CardActions>
          </Card>
        ))}
      </Box>

        {/* Affichage des macros calculés */}
        {recipe.macros.calories >= 0 && (
        <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Calories : {recipe.macros.calories.toFixed(2)} kcal</Typography>
            <Typography variant="body1">Glucides : {recipe.macros.carbohydrates.toFixed(2)} g</Typography>
            <Typography variant="body1">Protéines : {recipe.macros.proteins.toFixed(2)} g</Typography>
            <Typography variant="body1">Lipides : {recipe.macros.fats.toFixed(2)} g</Typography>
        </Box>
        )}

      {/* Bouton pour calculer les macros */}
      <Button onClick={calculateMacros} variant="contained" color="secondary" sx={{ mt: 4, width:'100%', boxShadow:'none' }}>
        Calculer les macros
      </Button>


      <TextField
        label="Instructions"
        name="instructions"
        value={recipe.instructions}
        onChange={handleInputChange}
        fullWidth
        multiline
        rows={4}
        sx={{ mt: 4 }}
      />

      {/* Bouton pour sauvegarder la recette */}
      <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ mt: 2, width:'100%', boxShadow:'none'  }}>
        Sauvegarder
      </Button>

      <Collapse in={showAlert}>
        <Alert
          severity="error"
          onClose={() => setShowAlert(false)}
          sx={{ mb: 2, mt:2 }}
        >
          {errorMessage}
        </Alert>
      </Collapse>

      </Paper>

    </Box>

    <Dialog open={successModalOpen} onClose={() => setSuccessModalOpen(false)}>
      <DialogTitle>Recette créée avec succès</DialogTitle>
      <DialogActions>
        <Button onClick={() => navigate('/recipes')} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>

    </>
  );
};

export default RecipeForm;
