export const adjustRecipeMacros = async (recipe, targetMacros) => {
    if (!recipe || !targetMacros) {
      throw new Error("Recette ou macros cibles manquantes.");
    }
  
    // Charger les données CIQUAL
    let ciqualData;
    try {
      const response = await fetch('/ciqual.json');
      ciqualData = await response.json();
    } catch (error) {
      throw new Error("Impossible de charger les données CIQUAL.");
    }
  
    // Fonction pour parser les valeurs nutritionnelles
    const parseNutrientValue = (value) => {
      if (typeof value === 'string') {
        value = value.replace(',', '.');
        if (value.includes('<') || value === 'traces' || value === '-' || value === '') {
          return 0;
        }
      }
      return parseFloat(value) || 0;
    };
  
    // Enrichir les ingrédients avec leurs données nutritionnelles
    const ingredientsWithNutrition = recipe.ingredients.map((ingredient) => {
      const ciqualEntry = ciqualData.find((item) => item.alim_code === parseInt(ingredient.ciqual_code, 10));
      if (!ciqualEntry) {
        throw new Error(`Aucune donnée CIQUAL trouvée pour l'ingrédient : ${ingredient.name}`);
      }
  
      return {
        ...ingredient,
        nutrition: {
          carbohydrates: parseNutrientValue(ciqualEntry["Glucides (g/100 g)"]),
          proteins: parseNutrientValue(ciqualEntry["Protéines, N x facteur de Jones (g/100 g)"]),
          fats: parseNutrientValue(ciqualEntry["Lipides (g/100 g)"]),
          calories: parseNutrientValue(ciqualEntry["Energie, Règlement UE N° 1169/2011 (kcal/100 g)"]),
        },
      };
    });
  
    // Calcul des ajustements individuels
    const totalMacros = recipe.macros;
    const adjustedIngredients = ingredientsWithNutrition.map((ingredient) => {
      const { carbohydrates, proteins, fats } = ingredient.nutrition;
      let adjustmentFactor = 1;
  
      // Calcul de l'ajustement spécifique pour chaque macro
      if (carbohydrates > proteins && carbohydrates > fats) {
        adjustmentFactor = targetMacros.carbohydrates / totalMacros.carbohydrates;
      } else if (proteins > carbohydrates && proteins > fats) {
        adjustmentFactor = targetMacros.proteins / totalMacros.proteins;
      } else if (fats > carbohydrates && fats > proteins) {
        adjustmentFactor = targetMacros.fats / totalMacros.fats;
      }
  
      return {
        ...ingredient,
        quantity: ingredient.quantity * adjustmentFactor,
      };
    });
  
    // Recalcul des macros ajustées
    const adjustedMacros = adjustedIngredients.reduce(
      (totals, ingredient) => {
        const { carbohydrates, proteins, fats, calories } = ingredient.nutrition;
        const quantity = ingredient.quantity;
  
        totals.carbohydrates += (carbohydrates * quantity) / 100;
        totals.proteins += (proteins * quantity) / 100;
        totals.fats += (fats * quantity) / 100;
        totals.calories += (calories * quantity) / 100;
  
        return totals;
      },
      { carbohydrates: 0, proteins: 0, fats: 0, calories: 0 }
    );
  
    return {
      ...recipe,
      ingredients: adjustedIngredients,
      macros: adjustedMacros,
    };
  };
  