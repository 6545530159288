import React from 'react';
import {
  Box,
  Typography,
  Divider,
  Chip,
  Grid,
  Card,
  CardContent,
  Avatar,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getRecipeEmoji } from '../../utils/getRecipeEmoji';

const RecipeView = ({ recipe, onClose = () => {} }) => {
  if (!recipe) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h5" color="error">
          Aucune recette sélectionnée.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {/* Bouton de fermeture */}
      <Box sx={{ position: 'absolute', top: '5px', left: '5px' }}>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Emoji de la recette */}
      <Box
        sx={{
          width: '100%',
          height: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#EEE',
          borderRadius: 2,
          mb: 2,
          fontSize: '4rem',
        }}
      >
        {getRecipeEmoji(recipe.name)}
      </Box>


      <Box sx={{ px:3 }}>

      {/* Nom de la recette */}
      <Typography variant="h3" sx={{ mb: 2, textAlign: 'left' }}>
        {recipe.name}
      </Typography>

      {/* Temps de préparation et cuisson */}
      <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'flex-start', mb: 2 }}>
        <Chip label={`Préparation : ${recipe.preparationTime} min`} />
        <Chip label={`Cuisson : ${recipe.cookingTime} min`} />
      </Box>

      {/* Ingrédients */}
      <Typography variant="h5" sx={{ mt: 3, mb: 1.5 }}>
        Ingrédients
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))',
          mt: 1,
        }}
      >
        {recipe.ingredients.map((ingredient, index) => (
          <Card
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #DDD',
              padding: 2,
              boxShadow: 'none',
              '&:hover': {
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                p: 0,
                paddingBottom: '0 !important',
              }}
            >
              <Avatar
                sx={{
                  fontSize: '1.5rem',
                  background: '#EEE',
                  mb: 1,
                  width: 46,
                  height: 46,
                }}
              >
                {getRecipeEmoji(ingredient.name)}
              </Avatar>
              <Typography variant="h6" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                {`${Math.round(ingredient.quantity)} g`}
              </Typography>
              <Typography variant="body2" sx={{ mt: 0.5 }}>
                {ingredient.name}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* Instructions */}
      <Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
        Instructions
      </Typography>
      <Divider />
      <Box component="ul" sx={{ pl: 2, mt: 1 }}>
        {recipe.instructions
          .split('.')
          .filter((instruction) => instruction.trim() !== '')
          .map((instruction, index) => (
            <Typography
              key={index}
              component="li"
              variant="body1"
              sx={{ mb: 1 }}
            >
              {instruction.trim()}.
            </Typography>
          ))}
      </Box>

      {/* Informations nutritionnelles */}
      <Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
        Nutrition
      </Typography>
      <Divider />
      <Grid container spacing={1} sx={{ mt: 1, mb: 6 }}>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Calories :</strong> {Math.round(recipe.macros.calories)} kcal
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Protéines :</strong> {Math.round(recipe.macros.proteins)} g
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Glucides :</strong> {Math.round(recipe.macros.carbohydrates)} g
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Lipides :</strong> {Math.round(recipe.macros.fats)} g
          </Typography>
        </Grid>
      </Grid>

    </Box>

    </Box>
  );
};

export default RecipeView;
