import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import Header from '../../components/Header';
import GeneralInfo from '../../components/dashboard/GeneralInfo';
import Recommendations from '../../components/dashboard/Recommendations';
import DayPlans from '../../components/dashboard/DayPlans';
import ShoppingList from '../../components/dashboard/ShoppingList';
import { createShoppingList } from '../../utils/createShoppingList';
import { adjustRecipeMacros } from '../../utils/adjustRecipeMacros';

const PlanView = () => {
  const { id } = useParams();
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shoppingList, setShoppingList] = useState([]);

  const adjustDayPlansRecipes = async (dayPlans, targetMacros) => {
    const adjustedRecipes = [];
    for (const dayPlan of dayPlans) {
      for (const meal of Object.values(dayPlan.meals)) {
        if (meal) {
          const adjustedRecipe = await adjustRecipeMacros(meal, targetMacros);
          adjustedRecipes.push(adjustedRecipe);
        }
      }
    }
    return adjustedRecipes;
  };

  useEffect(() => {
    const fetchPlanDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/weekplans/admin/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données du programme.');
        }

        const data = await response.json();

        setPlan(data);

        // Ajuster les recettes et générer la liste de courses
        const adjustedRecipes = await adjustDayPlansRecipes(data.dayPlans, data.user.targetMacros);
        const generatedList = createShoppingList(adjustedRecipes);
        setShoppingList(generatedList);
      } catch (err) {
        console.error('Erreur lors de la récupération des données :', err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPlanDetails();
  }, [id]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Header />
      <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', width: { xs: '-webkit-fill-available', md: '100%' }, margin: '0 auto' }}>
      <GeneralInfo
        user={plan.user}
        dietaryPreferences={plan.user?.dietaryPreferences}
        goals={plan.user?.goals}
        allergies={plan.user?.allergies}
        summary={plan.summary}
        targetMacros={plan.user?.targetMacros}
        planId={plan._id} 
      />
        <Recommendations recommendations={plan.recommendations} />
        <DayPlans dayPlans={plan.dayPlans} targetMacros={plan.user?.targetMacros} />
        <ShoppingList shoppingList={shoppingList} />
      </Box>
    </>
  );
};

export default PlanView;



