export const createShoppingList = (adjustedRecipes) => {
    const shoppingList = {};
  
    adjustedRecipes.forEach((recipe) => {
      recipe.ingredients.forEach((ingredient) => {
        const { name, quantity, unit } = ingredient;
  
        if (shoppingList[name]) {
          // Additionner les quantités si l'unité est la même
          if (shoppingList[name].unit === unit) {
            shoppingList[name].quantity += quantity;
          } else {
            console.warn(`Unit mismatch for ${name}, skipping aggregation.`);
          }
        } else {
          shoppingList[name] = { quantity, unit };
        }
      });
    });
  
    return Object.entries(shoppingList).map(([ingredient, { quantity, unit }]) => ({
      ingredient,
      quantity: Math.round(quantity),
      unit,
    }));
  };
  