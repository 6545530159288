import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Box,
  Typography,
  Divider,
  Chip,
  Grid,
  Alert,
  CircularProgress,
  IconButton,
  Card,
  CardContent,
  Avatar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { adjustRecipeMacros } from '../../utils/adjustRecipeMacros';
import { getRecipeEmoji } from '../../utils/getRecipeEmoji';

const RecipeDetails = ({ recipe, targetMacros, open, onClose }) => {
  const [adjustedRecipe, setAdjustedRecipe] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const adjustRecipe = async () => {
      if (recipe && targetMacros) {
        try {
          setLoading(true);
          const updatedRecipe = await adjustRecipeMacros(recipe, targetMacros);
          setAdjustedRecipe(updatedRecipe);
        } catch (err) {
          console.error("Erreur lors de l'ajustement des macros :", err);
          setAdjustedRecipe(recipe); // Revenir à la recette d'origine en cas d'échec
        } finally {
          setLoading(false);
        }
      }
    };

    adjustRecipe();
  }, [recipe, targetMacros]);

  if (loading) {
    return (
      <Drawer anchor="right" open={open} onClose={onClose}>
        <Box sx={{ width: 400, p: 3, textAlign: 'center' }}>
          <CircularProgress />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Chargement de la recette...
          </Typography>
        </Box>
      </Drawer>
    );
  }

  if (!adjustedRecipe) {
    return (
      <Drawer anchor="right" open={open} onClose={onClose}>
        <Box sx={{ width: 400, p: 3 }}>
          <Typography variant="h5">Aucune recette disponible.</Typography>
        </Box>
      </Drawer>
    );
  }

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box >
        {/* Bouton de fermeture */}
        <Box sx={{ position: 'absolute', top: '5px', left: '5px' }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Emoji de la recette */}
        <Box
          sx={{
            width: '100%',
            height: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#EEE',
            mb: 2,
            fontSize: '4rem',
          }}
        >
          {getRecipeEmoji(adjustedRecipe.name)}
        </Box>

      <Box sx={{ px:3 }}>

        {/* Nom de la recette */}
        <Typography variant="h3" sx={{ mb: 2, textAlign: 'left' }}>
          {adjustedRecipe.name}
        </Typography>

        {/* Temps de préparation et cuisson */}
        <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'flex-start', mb: 2 }}>
          <Chip label={`Préparation : ${adjustedRecipe.preparationTime} min`} />
          <Chip label={`Cuisson : ${adjustedRecipe.cookingTime} min`} />
        </Box>

        {/* Ingrédients */}
        <Typography variant="h5" sx={{ mt: 3, mb: 1.5 }}>
          Ingrédients
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))',
            mt: 1,
          }}
        >
          {adjustedRecipe.ingredients.map((ingredient, index) => (
            <Card
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #DDD',
                padding: 2,
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  p: 0,
                  paddingBottom: '0 !important',
                }}
              >
                <Avatar
                  sx={{
                    fontSize: '1.5rem',
                    background: '#EEE',
                    mb: 1,
                    width: 46,
                    height: 46,
                  }}
                >
                  {getRecipeEmoji(ingredient.name)}
                </Avatar>
                <Typography variant="h6" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                  {`${Math.round(ingredient.quantity)} g`}
                </Typography>
                <Typography variant="body2" sx={{ mt: 0.5 }}>
                  {ingredient.name}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
        <Alert severity="info" sx={{ mb: 2, mt: 2 }}>
          La recette a été ajustée automatiquement en fonction de vos besoins macro.
        </Alert>

        {/* Instructions */}
        <Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
          Instructions
        </Typography>
        <Divider />
        <Box component="ul" sx={{ pl: 2, mt: 1 }}>
          {adjustedRecipe.instructions
            .split('.')
            .filter((instruction) => instruction.trim() !== '')
            .map((instruction, index) => (
              <Typography
                key={index}
                component="li"
                variant="body1"
                sx={{ mb: 1 }}
              >
                {instruction.trim()}.
              </Typography>
            ))}
        </Box>

        {/* Informations nutritionnelles */}
        <Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
          Nutrition
        </Typography>
        <Divider />
        <Grid container spacing={1} sx={{ mt: 1, mb:6 }}>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Calories :</strong> {Math.round(adjustedRecipe.macros.calories)} kcal
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Protéines :</strong> {Math.round(adjustedRecipe.macros.proteins)} g
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Glucides :</strong> {Math.round(adjustedRecipe.macros.carbohydrates)} g
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Lipides :</strong> {Math.round(adjustedRecipe.macros.fats)} g
            </Typography>
          </Grid>
        </Grid>

        </Box>


      </Box>
    </Drawer>
  );
};

export default RecipeDetails;
