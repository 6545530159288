import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Drawer,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';
import { styled } from '@mui/system';
import UserMin from '../../components/plans/UserMin';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CalendarCell = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  padding: theme.spacing(2),
  textAlign: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

// Dictionnaire de correspondance des repas entre le français et l'anglais
const mealTranslations = {
  breakfast: 'Petit-déjeuner',
  lunch: 'Déjeuner',
  snack: 'Encas',
  dinner: 'Dîner',
};

const CATEGORY_TRANSLATIONS = {
  vegetarian: "Végétarien",
  vegan: "Vegan",
  "gluten-free": "Sans gluten",
  "dairy-free": "Sans produits laitiers",
  paleo: "Paléo",
  keto: "Kéto",
  other: "Autre",
};


// Pour récupérer la clé anglaise d'un repas français
const getMealKeyFromFrench = (frenchMeal) => {
  return Object.keys(mealTranslations).find((key) => mealTranslations[key] === frenchMeal);
};

const RecipeSelection = ({
  weekNumber,
  setWeekNumber,
  dayPlans,
  setDayPlans,
  handleDayPlanChange,
  recipes = [],
  userPreferences,
  selectedClient,
  nextStep,
  prevStep,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedMeal, setSelectedMeal] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState('');

  useEffect(() => {
    if (selectedClient) {
      // console.log('Selected Client:', selectedClient);
    }
    if (userPreferences) {
      // console.log('User Preferences:', userPreferences);
    }
    // console.log('dayPlans updated:', dayPlans);
    // console.log('recipes updated:', recipes);
  }, [selectedClient, userPreferences, dayPlans, recipes]);

  const openDrawer = (dayIndex, meal) => {
    setSelectedDay(dayIndex);
    setSelectedMeal(meal);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setSearchTerm('');
    setCategoryFilter('');
  };

  const handleDayPlanChangeAndClose = (dayIndex, meal, recipeId) => {
    const mealKey = getMealKeyFromFrench(meal);
    
    // Créer une copie de dayPlans pour éviter toute mutation directe
    const updatedDayPlans = [...dayPlans];
    
    // Assurer que chaque jour a bien une propriété meals qui est un objet
    if (!updatedDayPlans[dayIndex].meals) {
      updatedDayPlans[dayIndex].meals = {};
    }
  
    // Mettre à jour la recette sélectionnée pour le repas donné
    updatedDayPlans[dayIndex].meals[mealKey] = {
      _id: recipeId,
      ...recipes.find(recipe => recipe._id === recipeId) // Inclure d'autres détails si nécessaire
    };
  
    // Mettre à jour le state
    setDayPlans(updatedDayPlans);
    // console.log('Updated dayPlans:', updatedDayPlans);
  
    // Fermer le Drawer après la sélection de la recette
    closeDrawer();
  };
  

  const filterRecipes = (meal, searchTerm, categoryFilter) => {
    if (!meal) {
      return []; // Si meal est undefined, retourne un tableau vide
    }

    const mealKey = getMealKeyFromFrench(meal);
    return recipes
      .filter((recipe) => recipe.mealType?.toLowerCase() === mealKey?.toLowerCase())
      .filter((recipe) =>
        recipe.name?.toLowerCase().includes(searchTerm.toLowerCase() || '')
      )
      .filter((recipe) => (categoryFilter ? recipe.category.includes(categoryFilter) : true))
      .filter((recipe) => {
        // Filtrer en fonction des préférences alimentaires du client
        if (userPreferences && userPreferences.length > 0) {
          return userPreferences.some((preference) => recipe.category.includes(preference));
        }
        return true; // Si pas de préférence, renvoyer toutes les recettes
      });
  };

  const handleDayTypeChange = (dayIndex, dayType) => {
    const updatedDayPlans = [...dayPlans];
    updatedDayPlans[dayIndex].dayType = dayType;
    setDayPlans(updatedDayPlans);
  };

  useEffect(() => {
    console.log('Fetched week plan data:', dayPlans);
  }, [dayPlans]);
  
  const renderMealTitle = (dayIndex, mealKey) => {
    const meal = dayPlans[dayIndex]?.meals[mealKey];
    if (meal && meal._id) {
      // console.log('Rendering meal for day:', dayIndex, 'Meal Key:', mealKey, 'Meal ID:', meal._id);
      const foundRecipe = recipes.find((recipe) => String(recipe._id) === String(meal._id));
      if (foundRecipe) {
        // console.log('Found recipe:', foundRecipe);
        return foundRecipe.name;
      } else {
        // console.warn('Recipe not found for ID:', meal._id);
      }
    }
    return 'None';
  };
  

  return (
    <>

      <UserMin selectedClient={selectedClient} userPreferences={userPreferences} />

      <Grid container spacing={2} sx={{ marginBottom: 4 }}>
        {['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'].map((day, index) => (
          <Grid item xs={12} sm={6} md={4} key={day}>
            <CalendarCell sx={{ borderRadius: 1 }}>
              <Typography sx={{ textAlign: 'left', ml: 1 }} variant="h6">{day}</Typography>
              <FormControl fullWidth sx={{ mt: 2, mb: 0 }}>
                <InputLabel id={`day-type-select-label-${index}`}>Type de jour</InputLabel>
                <Select
                  labelId={`day-type-select-label-${index}`}
                  value={dayPlans[index]?.dayType || 'rest'}
                  label="Type de Jour"
                  onChange={(e) => handleDayTypeChange(index, e.target.value)}
                >
                  <MenuItem value="rest">Repos</MenuItem>
                  <MenuItem value="sport">Sport</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{ marginTop: 1 }}>
                {Object.values(mealTranslations).map((meal) => (
                  <Box key={meal} sx={{ marginBottom: 1, textAlign: 'left' }}>
                    <Button
                      variant="text"
                      sx={{ width: '100%', justifyContent: 'flex-start' }}
                      color="inherit"
                      startIcon={<AddIcon />}
                      onClick={() => openDrawer(index, meal)}
                    >
                      {meal.charAt(0).toUpperCase() + meal.slice(1)}
                    </Button>
                    {dayPlans[index]?.meals[getMealKeyFromFrench(meal)] && (
                      <Typography variant="body2" sx={{ mb: 1.5, pl: 0.8, display: 'flex', alignItems: 'center' }}>
                        <CheckCircleIcon sx={{ fontSize: '1.2em', mr: 0.5 }} color="success" /> {renderMealTitle(index, getMealKeyFromFrench(meal))}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            </CalendarCell>
          </Grid>
        ))}
      </Grid>

      <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
        <Box sx={{ width: 400, padding: 3 }}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Recette pour {['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'][selectedDay]} - {selectedMeal}
          </Typography>
          <TextField
            label="Rechercher"
            type="text"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <List>
            {filterRecipes(selectedMeal, searchTerm, categoryFilter).map((recipe) => (
              <ListItem key={recipe._id} disablePadding>
                <ListItemButton onClick={() => handleDayPlanChangeAndClose(selectedDay, selectedMeal, recipe._id)}>
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center">
                        {recipe.name}
                        <Chip
                          label={recipe.category.map(cat => CATEGORY_TRANSLATIONS[cat] || cat).join(", ")}
                          size="small"
                          sx={{ marginLeft: 1 }}
                        />
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      <Box
        sx={{
          display: 'flex',
          justifyContent: window.location.pathname.includes('edit') ? 'flex-end' : 'space-between',
        }}
      >
        {!window.location.pathname.includes('edit') && (
          <Button variant="contained" color="secondary" onClick={prevStep} sx={{ marginRight: 2 }}>
            Retour
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={nextStep}>
          Suivant
        </Button>
      </Box>

    </>
  );
};

export default RecipeSelection;
