import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, List, ListItem, Card, CardContent, Drawer, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AdjustRecipe = () => {
  const [clientName, setClientName] = useState('');
  const [macros, setMacros] = useState({ carbohydrates: '', proteins: '', fats: '' });
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [adjustedRecipe, setAdjustedRecipe] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    // Charger les recettes depuis /public/data
    const loadRecipes = async () => {
      try {
        const recipeFiles = [
          '/data/72244.json',
        ];
        const loadedRecipes = await Promise.all(
          recipeFiles.map(async (file) => {
            const response = await fetch(file);
            if (!response.ok) {
              throw new Error(`Erreur lors du chargement de ${file}`);
            }
            return response.json();
          })
        );
        setRecipes(loadedRecipes);
      } catch (error) {
        console.error('Erreur lors du chargement des recettes:', error);
      }
    };
    loadRecipes();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name in macros) {
      setMacros({ ...macros, [name]: value });
    } else {
      setClientName(value);
    }
  };

  const handleRecipeSelect = (recipe) => {
    setSelectedRecipe(recipe);
  };

  const adjustRecipe = () => {
    if (selectedRecipe) {
      const totalPercentage = parseFloat(macros.carbohydrates) + parseFloat(macros.proteins) + parseFloat(macros.fats);
      if (totalPercentage !== 100) {
        alert('Les pourcentages des macros doivent totaliser 100%.');
        return;
      }

      const carbsRatio = parseFloat(macros.carbohydrates) / selectedRecipe.macros.carbohydrates;
      const proteinsRatio = parseFloat(macros.proteins) / selectedRecipe.macros.proteins;
      const fatsRatio = parseFloat(macros.fats) / selectedRecipe.macros.fats;

      // Calculer une moyenne des ratios pour équilibrer les ajustements
      const averageRatio = (carbsRatio + proteinsRatio + fatsRatio) / 3;

      const adjustedIngredients = selectedRecipe.ingredients.map((ingredient) => {
        const adjustedQuantity = ingredient.quantity * averageRatio;
        return {
          ...ingredient,
          quantity: adjustedQuantity,
        };
      });

      const adjustedMacros = {
        calories: (selectedRecipe.macros.calories || 0) * averageRatio,
        carbohydrates: (selectedRecipe.macros.carbohydrates || 0) * averageRatio,
        proteins: (selectedRecipe.macros.proteins || 0) * averageRatio,
        fats: (selectedRecipe.macros.fats || 0) * averageRatio,
      };

      setAdjustedRecipe({ ...selectedRecipe, ingredients: adjustedIngredients, macros: adjustedMacros });
      setIsDrawerOpen(true);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography sx={{ mb:2 }}  variant="h4">Ajuster une recette</Typography>
      <TextField
        label="Nom du client"
        name="clientName"
        value={clientName}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Typography variant="h6">Besoins en Macros (en %)</Typography>
      <TextField
        label="Glucides (%)"
        name="carbohydrates"
        value={macros.carbohydrates}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Protéines (%)"
        name="proteins"
        value={macros.proteins}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Lipides (%)"
        name="fats"
        value={macros.fats}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Typography variant="h6">Sélectionnez une Recette</Typography>
      <List>
        {recipes.map((recipe) => (
          <ListItem button key={recipe.id} onClick={() => handleRecipeSelect(recipe)}>
            {recipe.name}
          </ListItem>
        ))}
      </List>
      {selectedRecipe && (
        <Card sx={{ mt: 4 }}>
          <CardContent>
            <Typography variant="h6">Recette Sélectionnée : {selectedRecipe.name}</Typography>
            <Typography variant="body2">Préparation : {selectedRecipe.preparationTime} min</Typography>
            <Typography variant="body2">Cuisson : {selectedRecipe.cookingTime} min</Typography>
            <Typography variant="body2">Ingrédients :</Typography>
            <ul>
              {selectedRecipe.ingredients.map((ingredient, index) => (
                <li key={index}>{ingredient.name} - {ingredient.quantity}g</li>
              ))}
            </ul>
          </CardContent>
        </Card>
      )}
      <Button
        variant="contained"
        onClick={adjustRecipe}
        disabled={!selectedRecipe}
        sx={{ mt: 4 }}
      >
        Ajuster la Recette
      </Button>
      <Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Box sx={{ width: 400, p: 3 }}>
          <IconButton onClick={() => setIsDrawerOpen(false)}>
            <CloseIcon />
          </IconButton>
          {adjustedRecipe && (
            <>
              <Typography variant="h6">Recette Ajustée : {adjustedRecipe.name}</Typography>
              <Typography variant="body2">Ingrédients Ajustés :</Typography>
              <ul>
                {adjustedRecipe.ingredients.map((ingredient, index) => (
                  <li key={index}>{ingredient.name} - {ingredient.quantity.toFixed(2)}g</li>
                ))}
              </ul>
              <Typography variant="h6" sx={{ mt: 2 }}>Informations Nutritionnelles</Typography>
              <Typography variant="body2">Calories : {adjustedRecipe.macros?.calories.toFixed(2) || 'N/A'}</Typography>
              <Typography variant="body2">Glucides : {adjustedRecipe.macros?.carbohydrates.toFixed(2) || 'N/A'}g</Typography>
              <Typography variant="body2">Protéines : {adjustedRecipe.macros?.proteins.toFixed(2) || 'N/A'}g</Typography>
              <Typography variant="body2">Lipides : {adjustedRecipe.macros?.fats.toFixed(2) || 'N/A'}g</Typography>
            </>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default AdjustRecipe;