import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, Paper, CircularProgress, Alert, Divider, Chip, Stack, LinearProgress, Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import Header from '../../components/Header'; 

const UserView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/${id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user details');
        }

        const data = await response.json();
        setUser(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [id]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  const calculateMacroPercentages = (macros) => {
    const total = (macros.carbohydrates || 0) + (macros.proteins || 0) + (macros.fats || 0);
    return {
      carbohydrates: total ? ((macros.carbohydrates || 0) / total) * 100 : 0,
      proteins: total ? ((macros.proteins || 0) / total) * 100 : 0,
      fats: total ? ((macros.fats || 0) / total) * 100 : 0,
    };
  };

  const macroPercentages = calculateMacroPercentages(user.targetMacros || {});

  return (
    <>

    <Header /> 

    <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', width: { xs: '-webkit-fill-available', md: '100%' }, margin: '0 auto' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Button variant="outlined" color='black' startIcon={<ArrowBackIcon />} onClick={() => navigate('/users')}>
          Retour
        </Button>
        <Button variant="contained" startIcon={<EditIcon />} onClick={() => navigate(`/users/edit/${id}`)}>
          Éditer
        </Button>
      </Stack>
      <Paper elevation={0} sx={{ p: { xs: 2, sm: 4 }, border:'1px solid #DDD' }}> 
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 4 }}>
          <Box>
            <Typography variant="h3">{user.firstName} {user.lastName}</Typography>
            <Chip label={user.active ? 'Actif' : 'Inactif'} color={user.active ? 'success' : 'error'} sx={{ mt: 1 }} />
          </Box>
        </Stack>

        <Divider sx={{ mb: 4 }} />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom sx={{ mb:2 }}>Informations de contact</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <EmailIcon sx={{ mr: 1 }} />
              <Typography><strong>Email:</strong> {user.email}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <PhoneIcon sx={{ mr: 1 }} />
              <Typography><strong>Téléphone:</strong> {user.phone || 'Non renseigné'}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom sx={{ mb:2 }}>Informations personnelles</Typography>
            <Box sx={{ mb: 2 }}>
              <Typography><strong>Âge:</strong> {user.age || 'Non renseigné'}</Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography><strong>Poids:</strong> {user.weight ? `${user.weight} kg` : 'Non renseigné'}</Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography><strong>Taille:</strong> {user.height ? `${user.height} cm` : 'Non renseigné'}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom sx={{ mb:2 }}>Niveau d'activité et objectifs</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography><strong>Niveau d'activité:</strong> {user.activityLevel || 'Non renseigné'}</Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography><strong>Objectifs:</strong> {user.goals || 'Non renseigné'}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom sx={{ mb:2 }}>Informations médicales</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <MedicalServicesIcon sx={{ mr: 1 }} />
              <Typography><strong>Conditions médicales:</strong> {user.medicalConditions.length > 0 ? user.medicalConditions.join(', ') : 'Aucune'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <FavoriteIcon sx={{ mr: 1 }} />
              <Typography><strong>Allergies:</strong> {user.allergies.length > 0 ? user.allergies.join(', ') : 'Aucune'}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom sx={{ mb:2 }}>Régime alimentaire</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <RestaurantIcon sx={{ mr: 1 }} />
              <Typography><strong>Régime:</strong> {user.dietaryPreferences.length > 0 ? user.dietaryPreferences.join(', ') : 'Aucun'}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom sx={{ mb:2 }}>Objectifs nutritionnels</Typography>
            <Box sx={{ mb: 2 }}>
              <Typography><strong>Glucides:</strong> {macroPercentages.carbohydrates.toFixed(2)}%</Typography>
              <LinearProgress variant="determinate" value={macroPercentages.carbohydrates} sx={{ mb: 2 }} />
              <Typography><strong>Protéines:</strong> {macroPercentages.proteins.toFixed(2)}%</Typography>
              <LinearProgress variant="determinate" value={macroPercentages.proteins} sx={{ mb: 2 }} />
              <Typography><strong>Lipides:</strong> {macroPercentages.fats.toFixed(2)}%</Typography>
              <LinearProgress variant="determinate" value={macroPercentages.fats} sx={{ mb: 2 }} />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
    </>
  );
};

export default UserView;
