import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box>
        <Typography variant="h3" gutterBottom>
          Politique de Confidentialité
        </Typography>
        <Typography variant="body1" paragraph>
          Cette politique de confidentialité explique comment nous collectons, utilisons, divulguons et protégeons vos informations lorsque vous utilisez notre application. Nous nous engageons à protéger votre vie privée et à garantir la sécurité de vos données personnelles.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Informations collectées
        </Typography>
        <Typography variant="body1" paragraph>
          Nous collectons les informations suivantes :
          <ul>
            <li>Nom, prénom, adresse e-mail, numéro de téléphone</li>
            <li>Âge, poids, taille, niveau d'activité, préférences alimentaires, allergies, conditions médicales</li>
            <li>Objectifs nutritionnels et informations sur les macros cibles (glucides, protéines, lipides)</li>
            <li>Informations sur la connexion et l'utilisation de l'application</li>
          </ul>
        </Typography>
        <Typography variant="h5" gutterBottom>
          Utilisation des informations
        </Typography>
        <Typography variant="body1" paragraph>
          Les informations collectées sont utilisées pour :
          <ul>
            <li>Créer et gérer votre compte utilisateur</li>
            <li>Personnaliser votre expérience et fournir des recommandations nutritionnelles</li>
            <li>Améliorer notre application et nos services</li>
            <li>Communiquer avec vous concernant les mises à jour, les offres et l'assistance</li>
          </ul>
        </Typography>
        <Typography variant="h5" gutterBottom>
          Partage des informations
        </Typography>
        <Typography variant="body1" paragraph>
          Nous ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas suivants :
          <ul>
            <li>Avec votre consentement explicite</li>
            <li>Pour respecter une obligation légale</li>
            <li>Pour protéger nos droits ou la sécurité des utilisateurs</li>
          </ul>
        </Typography>
        <Typography variant="h5" gutterBottom>
          Sécurité des informations
        </Typography>
        <Typography variant="body1" paragraph>
          Nous mettons en place des mesures de sécurité appropriées pour protéger vos informations contre tout accès non autorisé, altération, divulgation ou destruction.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Vos droits
        </Typography>
        <Typography variant="body1" paragraph>
          Vous avez le droit d'accéder à vos informations personnelles, de les corriger, de les supprimer ou de limiter leur utilisation. Pour exercer ces droits, veuillez nous contacter à l'adresse suivante : <Link href="mailto:support@weareyoco.com">support@weareyoco.com</Link>.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Modifications de cette politique
        </Typography>
        <Typography variant="body1" paragraph>
          Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Nous vous informerons de toute modification importante par e-mail ou via l'application.
        </Typography>
        <Typography variant="body1" paragraph>
          Dernière mise à jour : {new Date().toLocaleDateString()}
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
