import React from 'react';
import { Box, Typography, Button, Card, CardContent, Avatar } from '@mui/material';
import { Fastfood, FitnessCenter, EmojiEvents, PeopleAlt } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function OffersPage() {
  return (
    <>
      <Helmet>
        <title>Yoco - Nos offres</title>
        <meta name="description" content="Découvrez nos offres de coaching sportif, nutritionnel et bien-être, adaptées à tous les niveaux et besoins." />
      </Helmet>
      <Box sx={{ px: { xs: 2, sm: 6 }, pt: 6, pb: 6, maxWidth: '960px', margin: '0 auto' }}>

        {/* Page Title */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h1" sx={{ mb: 2 }}>
            NOS OFFRES
          </Typography>
          <Typography variant="h5" sx={{ mb: 4 }}>
            Atteignez vos objectifs avec des solutions personnalisées en sport et nutrition.
          </Typography>
          <Button variant="contained" component={Link} to="/contact" color="primary" size="large">
            En savoir plus
          </Button>
        </Box>

        {/* Offers Section */}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4, justifyContent: 'center' }}>

          {/* Nutrition */}
          <Box sx={{ flex: '1 1 calc(45% - 16px)' }}>
            <Card sx={{ height: '100%', border: '1px solid #DDD', p: 3 }} elevation={0}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar sx={{ bgcolor: 'black', mr: 2 }}>
                <Fastfood />
                  </Avatar>
                  <Typography variant="h4">Nutrition</Typography>
                </Box>
                <Typography variant="body1" sx={{ mb: 2, fontWeight:'bold' }}>
                  La clé pour devenir inarrêtable commence dans votre assiette. Une nutrition adaptée n’est pas seulement un carburant : c’est votre arme secrète pour atteindre vos objectifs.
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  • Découvrez nos solutions nutritionnelles<br/>
                  • Des plans alimentaires personnalisés pour transformer votre énergie et soutenir vos efforts physiques et/ou mentaux.<br/>
                  • Découvrez des recettes simples, savoureuses et parfaitement adaptées à vos besoins, que vous soyez homme ou femme.<br/>
                  • Ebook Nutrition : "Nutrition pour tous : le guide ultime."
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Demandez votre plan gratuit et commencez votre transformation dès aujourd’hui.
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/contact">
                Demandez votre plan gratuit
                </Button>
              </CardContent>
            </Card>
          </Box>

          {/* Sport & Nutrition */}
          <Box sx={{ flex: '1 1 calc(45% - 16px)' }}>
            <Card sx={{ height: '100%', border: '1px solid #DDD', p: 3 }} elevation={0}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar sx={{ bgcolor: 'black', mr: 2 }}>
                <FitnessCenter />
                  </Avatar>
                  <Typography variant="h4">Sport & Nutrition</Typography>
                </Box>
                <Typography variant="body1" sx={{ mb: 2, fontWeight:'bold' }}>
                  Il est temps de prendre les commandes ! La remise en forme et l’évolution personnelle et sportive ne sont pas un rêve inaccessible : elles commencent par un choix, ici et maintenant.
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  • Programmes personnalisés :<br/>
                  ◦ Remise en forme (4 semaines) : Découvrez une routine progressive.<br/>
                  ◦ Performance (8 semaines) : Un plan structuré pour repousser vos limites.<br/>
                  • Accompagnement nutritionnel : "Le combo gagnant : sport + nutrition."
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Rejoignez le programme et faites le premier pas vers une vie plus saine.
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/contact">
                  En savoir plus
                </Button>
              </CardContent>
            </Card>
          </Box>

          {/* Compétition */}
          <Box sx={{ flex: '1 1 calc(45% - 16px)' }}>
            <Card sx={{ height: '100%', border: '1px solid #DDD', p: 3 }} elevation={0}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar sx={{ bgcolor: 'black', mr: 2 }}>
                <EmojiEvents />
                  </Avatar>
                  <Typography variant="h4">Préparation à la compétition</Typography>
                </Box>
                <Typography variant="body1" sx={{ mb: 2, fontWeight:'bold' }}>
                  Les champions mangent, s’entraînent et pensent comme des gagnants.
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  • Programme de préparation (12 semaines) :<br/>
                  ◦ Plans sportifs spécifiques : Ski erg, sled push, course à pied fractionnée.<br/>
                  ◦ Plans alimentaires spécifiques et coaching mental.
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Téléchargez votre guide de compétition Hyrox.
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/contact">
                  Rejoindre le programme
                </Button>
              </CardContent>
            </Card>
          </Box>

          {/* Événements et Formations */}
          <Box sx={{ flex: '1 1 calc(45% - 16px)' }}>
            <Card sx={{ height: '100%', border: '1px solid #DDD', p: 3 }} elevation={0}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar sx={{ bgcolor: 'black', mr: 2 }}>
                    <PeopleAlt />
                  </Avatar>
                  <Typography variant="h4">Événements et formations</Typography>
                </Box>
                <Typography variant="body1" sx={{ mb: 2, fontWeight:'bold' }}>
                  Devenir inarrêtable, c’est aussi faire partie d’une équipe, relever des défis ensemble.
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  • Événements sportifs : Challenges en équipes, journées sportives.<br/>
                  • Formations pour entreprises : Séminaires sur gestion du stress et cohésion.
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Demandez un devis pour un événement sur mesure.
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/contact">
                  Demandez un devis
                </Button>
              </CardContent>
            </Card>
          </Box>
        </Box>

        {/* Call to Action Section */}
        <Box sx={{ backgroundColor: '#000', color: '#fff', width: '100%', py: 5, textAlign: 'center', mt: 12, borderRadius: 2 }}>
          <Typography variant="h2" sx={{ mb: 3 }}>
            Devenez inarrêtable !
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.2em', mb: 4, px: { xs: 2, sm: 15 } }}>
            Peu importe vos objectifs, nous avons une solution adaptée. Contactez-nous dès aujourd'hui pour découvrir votre potentiel.
          </Typography>
          <Button variant="contained" component={Link} to="/contact" color="primary" size="large">
            Prendre rendez-vous
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default OffersPage;