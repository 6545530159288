import React, { useContext, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

// Route publique - accessible uniquement aux utilisateurs non connectés
export const PublicRoute = () => {
  const { user, loading } = useContext(AuthContext);

  useEffect(() => {
    // console.log("PublicRoute - User:", user);
  }, [user]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return user ? <Navigate to="/" /> : <Outlet />;
};

// Route utilisateur - accessible uniquement aux utilisateurs connectés
export const UserRoute = () => {
  const { user, loading } = useContext(AuthContext);

  useEffect(() => {
    // console.log("UserRoute - User:", user);
  }, [user]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return user ? <Outlet /> : <Navigate to="/home" />;
};

// Route administrateur - accessible uniquement aux administrateurs
export const AdminRoute = () => {
  const { user, loading } = useContext(AuthContext);

  useEffect(() => {
    // console.log("AdminRoute - User:", user);
  }, [user]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    // console.log("AdminRoute - Redirection vers /login");
    return <Navigate to="/home" />;
  }

  if (user.role !== 'admin') {
    // console.log("AdminRoute - Redirection vers / (l'utilisateur n'est pas admin)");
    return <Navigate to="/week" />;
  }

  return <Outlet />;
};

const ProtectedRoutes = { PublicRoute, UserRoute, AdminRoute };
export default ProtectedRoutes;
